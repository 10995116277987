import MUIRichTextEditor from 'mui-rte';
import { makeStyles } from '@mui/styles';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';
import { useContext } from 'react';

const intuGreen = '#A8C957';
const intuLightGrey = '#00000061';

const createStyles = (userTheme) => makeStyles((theme) => ({
  editorContainer: {
    border: `1px solid ${userTheme.mode === 'dark' ? '#ffffff3b' : '#0000003b'}`,
    borderRadius: '0.375rem',
    padding: '0.5em 1em',
    '&:focus-within': {
      boxShadow: `0 0 0 1px ${userTheme.primary.main ? userTheme.primary.main : theme.palette.green.main} !important`,
      borderColor: `${userTheme.primary.main ? userTheme.primary.main : theme.palette.green.main} !important`
    },
    '&:hover': {
      border: `1px solid ${theme.palette.mode === 'dark' ? 'white' : 'black'}`
    }
  },
  editorContainerDisabled: {
    border: `1px solid ${intuLightGrey}`,
    borderRadius: '0.375rem',
    padding: '0.5em 1em',
  },
  editor: {
    minHeight: '4em',
    marginTop: '0.5em',
    maxHeight: "20em",
    overflow: 'auto',
  },
  toolbar: {
    borderBottom: `1px solid ${intuLightGrey}`,
  },
}));

export const IntuRichTextEditor = ({
  field,
  editorState,
  setEditorState,
  ...otherProps
}) => {

  const { userTheme } = useContext(IntuThemeContext);
  const useStyles = createStyles(userTheme);
  const classes = useStyles();

  return (
    <MUIRichTextEditor
      {...field}
      {...otherProps}
      toolbarButtonSize="small"
      controls={otherProps.controls || []}
      readOnly={otherProps?.disabled}
      toolbar={!otherProps?.disabled}
      classes={{
        root: otherProps?.disabled
          ? classes.editorContainerDisabled
          : classes.editorContainer,
        editor: classes.editor,
        toolbar: classes.toolbar,
      }}
    />
  );
};
