import { useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NewProductTypeAutocomplete from './NewProductTypeAutocomplete';
// import NewAttributeAutocomplete from './NewAttributeAutocomplete';
import NewApplicationAutocomplete from './NewApplicationAutocomplete';
import axios from '../../hooks/axios/useAxios';
import AttributeContainer from './AttributeContainer';
import ProductTypeField from '../product-type/ProductTypeField';

const NumberField = ({ ...props }) => {
  const { name, value, onChange, onBlur } = props;

  return (
    <TextField
      {...props}
      name={name}
      value={value || ''}
      onChange={(e) => {
        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        onChange(e, numericValue);
      }}
      onBlur={onBlur}
      type="number"
      InputProps={{
        inputProps: {
          min: 0,
          max: Infinity,
        },
      }}
    />
  );
};

export const StyledNumberField = styled(NumberField)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});

const currencyOptions = [
  { value: 'USD', label: 'US Dollar' },
  { value: 'EUR', label: 'Euro' },
  { value: 'GBP', label: 'British Pound' },
];

const ProductForm = ({
  product,
  setProduct,
  setIsFormValid,
  setIsUploadDocumentDialogOpen,
  productAttributeOptions,
  setProductAttributeOptions,
  handleExtractData,
}) => {
  const { t: transValidation } = useTranslation('validation');
  const { t: transFields } = useTranslation('fields');
  const formik = useFormikContext();

  const [hasDuplicateMPN, setHasDuplicateMPN] = useState(false);

  const handleChange = (e) => {
    formik.handleChange(e);
    setProduct((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleMPNChange = async (e) => {
    const { name, value } = e.target;

    setProduct((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

    formik.handleChange(e);
  };

  const handleMPNBlur = async (e) => {
    const { value } = e.target;

    if (value.length >= 6) {
      const payload = {
        mpn: value,
      };

      const { data, status } = await axios.post(
        '/api/products/search',
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (status === 200 && data.data) {
        const [found] = data.data;
        if (found._id !== product._id) {
          setHasDuplicateMPN(true);
          return;
        }
      }
    }

    setHasDuplicateMPN(false);
    formik.handleBlur(e);
  };

  useEffect(() => {
    setIsFormValid(formik.isValid);
  }, [formik.isValid]);

  const handleNewAttribute = (e, newAttribute) => {
    formik.handleChange(e);
    setProduct((prevState) => {
      return {
        ...prevState,
        product_attributes: [...prevState.product_attributes, newAttribute],
      };
    });
  };

  const handleNewApplication = (newApplication) => {
    formik.setFieldValue('product_applications', [
      ...product.product_applications,
      newApplication,
    ]);
    setProduct((prevState) => {
      return {
        ...prevState,
        product_applications: [
          ...prevState.product_applications,
          newApplication,
        ],
      };
    });
  };

  const handleDeleteApplication = (toDelete) => {
    if (Array.isArray(toDelete)) {
      formik.setFieldValue('product_applications', []);
      setProduct((prevState) => {
        return {
          ...prevState,
          product_applications: [],
        };
      });
      return;
    }

    formik.setFieldValue('product_applications', [
      ...product.product_applications.filter(
        (application) => application !== toDelete,
      ),
    ]);
    setProduct((prevState) => {
      return {
        ...prevState,
        product_applications: [
          ...prevState.product_applications.filter(
            (application) => application !== toDelete.label,
          ),
        ],
      };
    });
  };

  const handleDeleteProductType = (toDelete) => {
    if (Array.isArray(toDelete)) {
      formik.setFieldValue('product_types', []);
      setProduct((prevState) => {
        return {
          ...prevState,
          product_types: [],
        };
      });
      return;
    }

    formik.setFieldValue('product_types', [
      ...product.product_types.filter((type) => type !== toDelete),
    ]);
    setProduct((prevState) => {
      return {
        ...prevState,
        product_types: [
          ...prevState.product_types.filter((type) => type !== toDelete.value),
        ],
      };
    });
  };

  const handleNewProductType = (newProductType) => {
    formik.setFieldValue('product_types', [
      ...product.product_types,
      newProductType,
    ]);
    setProduct((prevState) => {
      return {
        ...prevState,
        product_types: [...prevState.product_types, newProductType],
      };
    });
  };

  return (
    <Form style={{ marginBottom: '20px' }}>
      <InputLabel id="new-product-name-label" sx={{ fontSize: '1.2rem' }}>
        {transFields('products.name_label')}
      </InputLabel>
      <TextField
        id="new-product-name-field"
        label={transFields('products.name_placeholder')}
        name="product_name"
        type="text"
        variant="outlined"
        margin="normal"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.product_name && formik.touched.product_name}
        helperText={
          formik.errors.product_name && formik.touched.product_name
            ? formik.errors.product_name
            : ''
        }
        value={product.product_name}
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        my={2}
      >
        <Box flex={0.4}>
          <InputLabel id="new-product-mpn-label" sx={{ fontSize: '1.2rem' }}>
            {transFields('products.mpn_label_alt')}
          </InputLabel>
          <TextField
            id="new-product-mpn-field"
            label={transFields('products.mpn_placeholder')}
            name="mpn"
            type="text"
            variant="outlined"
            onChange={handleMPNChange}
            onBlur={handleMPNBlur}
            error={(formik.errors.mpn && formik.touched.mpn) || hasDuplicateMPN}
            helperText={
              hasDuplicateMPN
                ? transValidation('product.mpn_duplicate')
                : formik.errors.mpn && formik.touched.mpn
                  ? formik.errors.mpn
                  : ''
            }
            value={product.mpn}
          />
        </Box>
      </Box>

      {/* TO DO: Change this to component/products/ProductTypefields */}
      <NewProductTypeAutocomplete
        product={product}
        handleNewProductType={handleNewProductType}
        handleDeleteProductType={handleDeleteProductType}
      />

      <AttributeContainer
        product={product}
        setProduct={setProduct}
        productAttributeOptions={productAttributeOptions}
        setProductAttributeOptions={setProductAttributeOptions}
        handleExtractData={handleExtractData}
      />

      {/* TO DO: Change this to component/aplications/ApplicationsField */}
      <NewApplicationAutocomplete
        product={product}
        handleNewApplication={handleNewApplication}
        handleDeleteApplication={handleDeleteApplication}
      />

      <Box display="flex" flexDirection="column" my={4}>
        <Typography variant="h4" textTransform="none">
          {transFields('products.structure')}
        </Typography>
        <Box display="flex" my={2} gap={4}>
          <FormControl sx={{ flex: 0.4 }}>
            <Typography mb={2}>{transFields('products.currency')}</Typography>
            <Select
              id="new-product-currency-label-select"
              onChange={handleChange}
              label={transFields('products.currency')}
              name="currency"
              defaultValue={currencyOptions[0].value}
              error={formik.errors.currency && formik.touched.currency}
              value={product.currency}
            >
              {currencyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <Typography mb={2}>{transFields('products.list_price')}</Typography>
            <StyledNumberField
              id="new-product-list-price-field"
              label={transFields('products.list_price')}
              name="list_price"
              type="number"
              variant="outlined"
              value={product.list_price}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.list_price && formik.touched.list_price}
              helperText={
                formik.errors.list_price && formik.touched.list_price
                  ? formik.errors.list_price
                  : ''
              }
            />
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <Typography mb={2}>{transFields('products.min_price')}</Typography>
            <StyledNumberField
              id="new-product-minimum_price-field"
              label={transFields('products.min_price')}
              name="minimum_price"
              type="number"
              variant="outlined"
              value={product.minimum_price}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.errors.minimum_price && formik.touched.minimum_price
              }
              helperText={
                formik.errors.minimum_price && formik.touched.minimum_price
                  ? formik.errors.minimum_price
                  : ''
              }
            />
          </FormControl>
        </Box>
      </Box>
    </Form>
  );
};

export default ProductForm;
