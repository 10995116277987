import React from 'react';
import { ScrollingCards } from '../../../../components/motion/ScrollingCards';
import { Grid, Typography } from '@mui/material';
import { ButtonHoverGradiant } from '../../../../components/buttons/ButtonHoverGradiant';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BarChartIcon from '@mui/icons-material/BarChart';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useTranslation } from 'react-i18next';
import { useIntuTheme } from '../../../../context/providers/IntuThemeProvider';
import { navigateToInnerDiv } from '../../../../helpers/navigateToDivId';

const YourAdvantages = () => {
  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks.lead_context',
  });

  // Responsiveness
  const { mobileView } = useIntuTheme();

  const mfgCardContent = [
    <PersonSearchIcon
      sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }}
    />,
    <ManageAccountsIcon
      sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }}
    />,
    <BarChartIcon sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }} />,
    <LibraryBooksIcon
      sx={{ fontSize: { xs: 36, md: 96 }, color: '#4d4d4d' }}
    />,
  ].map((icon, index) => {
    return (
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={10}
          container
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'space-around' }}
          textAlign="center"
        >
          {/* Icon & Label */}
          <Grid item xs={5} container direction="column" alignItems="center">
            <Grid item>{icon}</Grid>
            <Grid item>
              <Typography variant="h5" align="center" sx={{ color: '#4d4d4d' }}>
                {t(`manu.additional_content.advantages.header${index + 1}`)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Typography
              variant={mobileView ? 'body1' : 'body'}
              sx={{ color: '#4d4d4d' }}
              align="center"
            >
              {t(`manu.additional_content.advantages.statement${index + 1}`)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <ScrollingCards
      contentArr={mfgCardContent}
      header={
        <Typography variant="h1">
          {t('manu.additional_content.advantages.header')}
        </Typography>
      }
    />
  );
};

export { YourAdvantages };
