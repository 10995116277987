import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavBarDrawerContext } from '../../../context/providers/NavBarDrawerContextProvider';
import {
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { motion } from 'framer-motion';
import { NavList, NavItem } from '../../../framer-motion/animations';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import useAuth from '../../../hooks/useAuth';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker';
import UserContext from '../../../context/providers/UserInfoProvider';

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

const AllAccountsNavItems = () => {
  const navigate = useNavigate();
  const { auth, isAdmin } = useAuth();
  const { conversations } = useContext(UserContext);
  const theme = useTheme();
  const { handleDrawerClose } = useContext(NavBarDrawerContext);
  const { gaEventTracker } = useAnalyticsEventTracker();
  const { t } = useTranslation('buttons', { keyPrefix: 'navigation' });

  const numUnreadMessages = useMemo(() => {
    if (!conversations || !conversations.length) return 0;
    return conversations.reduce((acc, curr) => acc + curr.unread_count, 0);
  }, [conversations]);

  function handleNavigate(target) {
    if (target === 'Dashboard') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User Dashboard',
      });
      if (isAdmin === true) {
        return navigate('/app/admin/Dashboard');
      } else {
        return navigate('/app/user/Dashboard');
      }
    } else if (target === 'UserProfile') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User Profile',
      });
      return navigate('/app/user/profile');
    } else if (target === 'Messages') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Messages',
      });
      return navigate('/app/chat');
    } else if (target === 'CompanyProfile') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Company Profile',
      });
      return navigate('/app/company/profile');
    } else if (target === 'CompleteAccount') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Complete Account',
      });
      return navigate('/app/account/complete');
    }
    handleDrawerClose();
  }

  const generalNavItems = [
    {
      index: 1,
      key: 'Home',
      label: t('home'),
      color: theme.palette.text.primary,
      icon: (
        <HomeRoundedIcon
          sx={{ color: theme.palette.secondary.backgroundColor }}
        />
      ),
      target: () => handleNavigate('Dashboard'),
      event: 'User Dashboard',
      visible: auth?.user_info?.account_complete,
    },
    {
      index: 2,
      key: 'Messages',
      label: t('messages'),
      icon: (
        <Badge badgeContent={numUnreadMessages} color="primary">
          <MailOutlineIcon />
        </Badge>
      ),
      target: () => handleNavigate('Messages'),
      visible: !IS_PRODUCTION && auth?.user_info?.account_complete,
    },
    {
      index: 3,
      key: 'Profile',
      label: t('user_profile'),
      color: theme.palette.text.primary,
      icon: (
        <PersonIcon sx={{ color: theme.palette.secondary.backgroundColor }} />
      ),
      target: () => handleNavigate('UserProfile'),
      event: 'User Profile',
      visible: auth?.user_info?.account_complete,
    },
    {
      index: 4,
      key: 'Company',
      label: t('company_profile'),
      icon: (
        <BusinessIcon sx={{ color: theme.palette.secondary.backgroundColor }} />
      ),
      target: () => handleNavigate('CompanyProfile'),
      event: 'Company Profile',
      visible:
        auth?.user_info?.company && auth?.user_info?.account_type !== 'intutec',
    },
    {
      index: 5,
      key: 'Complete Account',
      label: t('complete_account'),
      icon: (
        <CheckCircleOutlineIcon
          sx={{ color: theme.palette.secondary.backgroundColor }}
        />
      ),
      target: () => handleNavigate('CompleteAccount'),
      event: 'Account Completion',
      visible: auth?.user_info?.account_complete === false,
    },
  ];
  return (
    <List>
      {generalNavItems.map(
        (menuItem) =>
          menuItem.visible && (
            <motion.div
              variants={NavList}
              initial="hidden"
              animate="visible"
              key={menuItem.key}
            >
              <ListItem sx={{ display: 'block' }}>
                <motion.div variants={NavItem} whileHover={{ scale: 1.1 }}>
                  <ListItemButton
                    sx={{
                      height: '2.5rem',
                      justifyContent: 'initial',
                      px: 2.5,
                      fontSize: '14px',
                    }}
                    onClick={() => {
                      menuItem.target();
                      handleDrawerClose();
                    }}
                  >
                    <ListItemIcon
                    // sx={{
                    //   color:
                    //     menuItem?.color || theme.palette.primary.contrastText,
                    // }}
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      // sx={{ color: theme.palette.primary.contrastText }}
                      primary={menuItem.label}
                      disableTypography
                    />
                  </ListItemButton>
                </motion.div>
              </ListItem>
            </motion.div>
          ),
      )}
    </List>
  );
};

export default AllAccountsNavItems;
