import { useMemo } from 'react';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { getFileName } from '../new-product/ProductFiles';

const PublicProductFiles = ({ files, images }) => {
  const handleDownload = (url, name) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(`Error downloading the ${name}:`, error));
  };

  const threeDImages = useMemo(() => {
    return images.filter((img) => img.endsWith('.gltf'));
  }, [images]);

  if (!files.length && !threeDImages.length) return null;
  return (
    <Box display="flex" flexDirection="column" flex={0.3}>
      <Typography mb={2}>Documents & Datasheets:</Typography>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.default',
        }}
      >
        {files?.map((file) => {
          return (
            <ListItem
              key={file}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                my: 1,
                border: '1px solid',
                borderColor: 'primary.main',
                borderRadius: '5px',
              }}
            >
              <ListItemAvatar>
                <Avatar src={''} alt="profile picture">
                  <DescriptionIcon sx={{ color: 'primary.dark' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText secondary={getFileName(file)} sx={{ mr: 2 }} />
              <IntuIconButton
                size="small"
                type="add"
                variant="outlined"
                tooltipTitle={'Download'}
                onClick={() => handleDownload(file, getFileName(file))}
                IconComponent={DownloadIcon}
              />
            </ListItem>
          );
        })}
        {threeDImages?.map((image) => {
          return (
            <ListItem
              key={image}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                my: 1,
                border: '1px solid',
                borderColor: 'primary.main',
                borderRadius: '5px',
              }}
            >
              <ListItemAvatar>
                <Avatar src={''} alt="profile picture">
                  <ViewInArIcon sx={{ color: 'primary.dark' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText secondary={getFileName(image)} sx={{ mr: 2 }} />
              <IntuIconButton
                size="small"
                type="add"
                variant="outlined"
                tooltipTitle={'Download'}
                onClick={() => handleDownload(image, getFileName(image))}
                IconComponent={DownloadIcon}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default PublicProductFiles;
