import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import AdjustIcon from '@mui/icons-material/Adjust';
import PeopleIcon from '@mui/icons-material/People';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import FeedIcon from '@mui/icons-material/Feed';
import EventIcon from '@mui/icons-material/Event';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { NavBarDrawerContext } from '../../../context/providers/NavBarDrawerContextProvider';
import { motion } from 'framer-motion';
import { NavList, NavItem } from '../../../framer-motion/animations';
import { useTranslation } from 'react-i18next';

const AdminNavItems = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation('buttons', {
    keyPrefix: 'navigation',
  });

  const { handleDrawerClose } = useContext(NavBarDrawerContext);

  const roleNavItems = [
    {
      key: 'Users',
      label: t('users'),
      icon: <PeopleIcon />,

      target: () => navigate('/app/admin/manage-users'),
      visible: true,
    },
    {
      key: 'Leads',
      label: t('leads'),

      icon: <AdjustIcon />,
      target: () => navigate('/app/admin/manage-leads'),
      visible: true,
    },
    {
      key: 'Events',
      label: t('events'),

      icon: <EventIcon />,
      target: () => navigate('/app/admin/manage-events'),
      visible: true,
    },
    {
      key: 'Contacts',
      label: t('contacts'),

      icon: <ContactMailIcon />,
      target: () => navigate('/app/admin/manage-contacts'),
      visible: true,
    },
    {
      key: 'Posts',
      label: t('posts'),

      icon: <FeedIcon />,
      target: () => navigate('/app/admin/posts'),
      event: 'Blog',
      visible: true,
    },
    {
      key: 'Products',
      label: t('products'),

      icon: <PrecisionManufacturingIcon />,
      target: () => navigate('/app/company/products'),
      visible: true,
    },
    {
      index: 5,
      key: 'Brands',
      label: 'Find Brands',
      icon: <TravelExploreIcon />,
      target: () => navigate('/app/find/brands'),
      visible: true,
    },
  ];

  return (
    <>
      {roleNavItems.map(
        (menuItem, index) =>
          menuItem.visible && (
            <motion.div
              variants={NavList}
              initial="hidden"
              animate="visible"
              key={index}
            >
              <ListItem key={index} sx={{ display: 'block' }}>
                <motion.div variants={NavItem} whileHover={{ scale: 1.1 }}>
                  <ListItemButton
                    sx={{
                      height: '2rem',
                      justifyContent: 'initial',
                      px: 2.5,
                      fontSize: '14px',
                    }}
                    onClick={() => {
                      menuItem.target();
                      handleDrawerClose();
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          menuItem?.color || theme.palette.primary.contrastText,
                      }}
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={menuItem.label} disableTypography />
                  </ListItemButton>
                </motion.div>
              </ListItem>
            </motion.div>
          ),
      )}
    </>
  );
};

export default AdminNavItems;
