import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import FooterComponent from '../footer/Footer';
import ChatsFooterContainer from '../public-product/ChatsFooterContainer';
import NavigationFooter from '../footer/NavigationFooter';

const NonAuthLayout = () => {
  return (
    <>
      <Container
        maxWidth="xxl"
        component="main"
        disableGutters
        sx={{
          flex: '1 auto',
        }}
      >
        <Outlet />
      </Container>
      <FooterComponent />
      <NavigationFooter />
      <ChatsFooterContainer />
    </>
  );
};

export default NonAuthLayout;
