import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './context/providers/AuthProvider.jsx';
import { UserProvider } from './context/providers/UserInfoProvider';
import { ResponseProvider } from './context/providers/ResponseProvider';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import ReactGA from 'react-ga4';

import App from './App';
import './index.css';

// Page Builder Styles
import { createGenerateClassName, StylesProvider } from '@mui/styles';

import './fonts/UBUNTU-Regular.ttf';
import './fonts/Avenir-Light.ttf';

// Import Mui Theme
import { IntuThemeProvider } from './context/providers/IntuThemeProvider';

// Response Status Snackbar
import { ProcessingProvider } from './context/providers/ProcessingProvider';

// Mui Date Picker Localization Provider
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Am Charts Module
import * as am5 from '@amcharts/amcharts5';

// Cookie Consent
import { CookieConsentContainer } from './components/cookieConsent/CookieConsent.jsx';

// Translator
import './i18n.js';

// Initialize Log Roket
import LogRocket from 'logrocket';

// Context Providers
import LayoutContextProvider from './context/providers/LayoutContextProvider.jsx';
import { NavBarDrawerContextProvider } from './context/providers/NavBarDrawerContextProvider.jsx';
import { BrandsProvider } from './context/providers/BrandsProvider.jsx';
import { ProductTypeProvider } from './context/providers/ProductTypeContextProvider.jsx';
import { ApplicationsProvider } from './context/providers/ApplicationsContextProvider.jsx';
import { TwoFactorVerificationProvider } from './context/providers/TwoFactorVerificationContextProvider.jsx';
import { IndustriesProvider } from './context/providers/IndustriesContextProvider.jsx';
import { ProductMeshProvider } from './components/new-product/context/ProductMeshContext.jsx';
import { NavigationFooterProvider } from './context/footer/NavigationFooterContext.jsx';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('aslli3/in2tec');
  Hotjar.init(process.env.REACT_APP_HOTJAR_SITE_ID, 6);
}

// SetUp Google Tag Mananger
const trackingId = process.env.REACT_APP_TRACKING_ID;

if (trackingId) {
  // Dynamically create and inject the gtag script tag
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  script.async = true;
  document.head.appendChild(script);

  // Initialize gtag once the script is loaded
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', trackingId, { send_page_view: false });
  };
}

ReactGA.initialize(trackingId, { gaOptions: { send_page_view: false } });

// Initialize LinkedIn Tracking
const initLinkedInTracking = () => {
  // Add the LinkedIn partner ID script
  window._linkedin_partner_id = '5802666';
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

  // Create and insert the tracking script
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';

  // Append the script to the head or footer
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(script, firstScript);

  // Define the lintrk function if not already present
  if (!window.lintrk) {
    window.lintrk = function (a, b) {
      window.lintrk.q.push([a, b]);
    };
    window.lintrk.q = [];
  }
};

// Initialize LinkedIn tracking on component mount
initLinkedInTracking();

// Initialize amCharts License
am5.addLicense(process.env.REACT_APP_AM5_LICENSE);
am5.addLicense(process.env.REACT_APP_AM5_MAP_LICENSE);

// Install CAPTCHA
// const root = ReactDOM.createRoot(document.getElementById("root"));
const container = document.getElementById('root');
const isPreRendered = !!window.snapSaveState; // Check if content was pre-rendered by react-snap
const root = isPreRendered
  ? ReactDOM.hydrateRoot(container)
  : ReactDOM.createRoot(container);

const history = createBrowserHistory({ basename: '/' });

// Mui Code for Page Builder
const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: 'mui-jss',
});

root.render(
  <BrowserRouter basename="/">
    <AuthProvider>
      <IntuThemeProvider>
        <StylesProvider generateClassName={generateClassName}>
          <UserProvider>
            <ResponseProvider>
              <ProcessingProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CookieConsentContainer />
                  <NavBarDrawerContextProvider>
                    <BrandsProvider>
                      <ProductTypeProvider>
                        <IndustriesProvider>
                          <ApplicationsProvider>
                            <LayoutContextProvider>
                              <TwoFactorVerificationProvider>
                                <ProductMeshProvider>
                                  <NavigationFooterProvider>
                                    <Routes history={history}>
                                      <Route
                                        path="/*"
                                        element={<App id="root" />}
                                      />
                                    </Routes>
                                  </NavigationFooterProvider>
                                </ProductMeshProvider>
                              </TwoFactorVerificationProvider>
                            </LayoutContextProvider>
                          </ApplicationsProvider>
                        </IndustriesProvider>
                      </ProductTypeProvider>
                    </BrandsProvider>
                  </NavBarDrawerContextProvider>
                </LocalizationProvider>
              </ProcessingProvider>
            </ResponseProvider>
          </UserProvider>
        </StylesProvider>
      </IntuThemeProvider>
    </AuthProvider>
  </BrowserRouter>,
);
