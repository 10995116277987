import { createContext, useState } from 'react';

export const ProductMeshContext = createContext();

export const ProductMeshProvider = ({ children }) => {
  const [initialMeshes, setInitialMeshes] = useState([]);
  const [meshes, setMeshes] = useState([]);
  const [cameraPosition, setCameraPosition] = useState([0, 0, 50]);
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [isProductImageDirtied, setIsProductImageDirtied] = useState(false);

  return (
    <ProductMeshContext.Provider
      value={{
        initialMeshes,
        setInitialMeshes,
        meshes,
        setMeshes,
        cameraPosition,
        setCameraPosition,
        rotationX,
        setRotationX,
        rotationY,
        setRotationY,
        isProductImageDirtied,
        setIsProductImageDirtied,
      }}
    >
      {children}
    </ProductMeshContext.Provider>
  );
};
