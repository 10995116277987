import { useContext } from 'react';
import mathiasKrostewitz from '../../../assets/how-it-works/mk.png';
import philOh from '../../../assets/how-it-works/po.png';
import {
  Avatar,
  Grid,
  Typography,
  Container,
  Button,
  Box,
} from '@mui/material';
import { ForceDirectedTreeContext } from '../../../charts/forceDirectedTree.js';
import { MapChartContext } from '../../../charts/mapChart.js';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import { useTranslation, Trans } from 'react-i18next';
import { InvestorHeaderContent } from '../headers/InvestorHeaderContent.jsx';
import { RevenueCalculator } from '../calculators/RevenueCalculator.jsx';
import useIntuTheme from '../../../hooks/useIntuTheme.js';
import { WhatMakesUsDifferent } from '../containers/WhatMakesUsDifferent.jsx';
import { RevenueModel } from '../calculators/RevenueModel.jsx';
import { Call } from '@mui/icons-material';
import { CalendarIcon } from '@mui/x-date-pickers';
import BrandsInfiniteSliderContainer from '../../../containers/brandsInfiniteSliderContainer/BrandsInfiniteSliderContainer.jsx';

// Colors for Data
const intuMiddleGrey = '#6F7978';

// Market size Data

// Chart Data
const marketSizeChart = (t) => {
  return {
    id: 'marketSizeChart',
    description: t('market_size.chart.description'),
    description: null,
    data: [
      {
        name: t('market_size.chart.north_america'),
        code: ['northAmerica'],
        latitude: 39.563353,
        longitude: -99.316406,
        color: '#A8C957',
        marketSize: 18.582,
        year: 2024,
        marketSizeUnit: 'Bill. $',
        value: 10,
        CAGR: 8.8,
        data: ['CA', 'MX', 'US'],
      },
      {
        name: t('market_size.chart.south_america'),
        code: ['southAmerica'],
        latitude: -17.995042363908354,
        longitude: -59.05840629780355,
        color: '#6ea4d4',
        marketSize: 2.405,
        year: 2024,
        marketSizeUnit: 'Bill. $',
        value: 10,
        CAGR: 4.16,
        data: [
          'AR',
          'BO',
          'BR',
          'CL',
          'CO',
          'CR',
          'CU',
          'DO',
          'EC',
          'SV',
          'GT',
          'HN',
          'NI',
          'PA',
          'PY',
          'PE',
          'PR',
          'UY',
          'VE',
          'BZ',
          'GY',
          'SR',
        ],
      },
      {
        name: t('market_size.chart.asia'),
        code: ['asia', 'oceania'],
        color: '#e8d46f',
        latitude: 47.212106,
        longitude: 103.183594,
        marketSize: 25.275,
        year: 2024,
        marketSizeUnit: 'Bill. $',
        value: 10,
        CAGR: 8.59,
        data: [
          'AF',
          'AU',
          'BD',
          'BN',
          'BT',
          'KH',
          'CN',
          'TL',
          'FJ',
          'IN',
          'ID',
          'JP',
          'KI',
          'KP',
          'KR',
          'LA',
          'MY',
          'MV',
          'MH',
          'FM',
          'MM',
          'NR',
          'NP',
          'NZ',
          'PK',
          'PG',
          'PH',
          'PW',
          'WS',
          'SB',
          'LK',
          'TH',
          'TO',
          'TV',
          'VN',
          'VU',
          'TM',
          'UZ',
          'TJ',
          'KG',
          'MN',
        ],
      },
      {
        name: t('market_size.chart.africa'),
        code: ['africa'],
        color: '#ededed',
        latitude: 11.081385,
        longitude: 21.621094,
        year: 2024,
        marketSize: 6.608,
        value: 10,
        marketSizeUnit: 'Bill. $',
        CAGR: 11.17,
        data: [
          'DZ',
          'AO',
          'BJ',
          'BW',
          'BF',
          'BI',
          'CV',
          'CM',
          'CF',
          'TD',
          'KM',
          'CD',
          'CG',
          'DJ',
          'EG',
          'GQ',
          'ER',
          'SZ',
          'ET',
          'GA',
          'GM',
          'GH',
          'GN',
          'GW',
          'CI',
          'KE',
          'LS',
          'LR',
          'LY',
          'MG',
          'MW',
          'ML',
          'MR',
          'MU',
          'YT',
          'MA',
          'MZ',
          'NA',
          'NE',
          'NG',
          'RE',
          'RW',
          'ST',
          'SN',
          'SC',
          'SL',
          'SO',
          'ZA',
          'SS',
          'SD',
          'TZ',
          'TG',
          'TN',
          'UG',
          'EH',
          'ZM',
          'ZW',
          'BH',
          'CY',
          'IR',
          'IQ',
          'IL',
          'JO',
          'KW',
          'LB',
          'OM',
          'PS',
          'QA',
          'SA',
          'SY',
          'TR',
          'AE',
          'YE',
        ],
      },
      {
        name: t('market_size.chart.europe'),
        code: ['europe'],
        color: '#d76446',
        latitude: 50.896104,
        longitude: 19.160156,
        marketSize: 27.728,
        year: 2024,
        marketSizeUnit: 'Bill. $',
        value: 10,
        CAGR: 10.78,
        data: [
          'AL',
          'AD',
          'AM',
          'AT',
          'AZ',
          'BY',
          'BE',
          'BA',
          'BG',
          'HR',
          'CY',
          'CZ',
          'DK',
          'EE',
          'FI',
          'FR',
          'GE',
          'DE',
          'GR',
          'HU',
          'IS',
          'IE',
          'IT',
          'KZ',
          'XK',
          'LV',
          'LI',
          'LT',
          'LU',
          'MT',
          'MD',
          'MC',
          'ME',
          'NL',
          'MK',
          'NO',
          'PL',
          'PT',
          'RO',
          'RU',
          'SM',
          'RS',
          'SK',
          'SI',
          'ES',
          'SE',
          'CH',
          'TR',
          'UA',
          'GB',
          'VA',
        ],
      },
    ],
  };
};

const InvestorContent = () => {
  const { leadInfo } = useContext(LeadContext);
  const { mobileView } = useIntuTheme();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks.lead_context.investor',
  });

  const { t: transCommon } = useTranslation('common');
  const marketSize = 8000000000;

  function processCompetitorSalesData(competitorSalesData, marketSize) {
    let totalDetailedRevenue = 0;
    let dataChildren = [];

    competitorSalesData.forEach((channelData) => {
      let channelInfo = Object.values(channelData)[0];
      let children = [];
      let channelRevenue = 0;

      if (channelInfo.type === 'estimate' || channelInfo.type === 'detailed') {
        if (channelInfo.type === 'detailed') {
          channelInfo.data.forEach((competitorData) => {
            const revenue = competitorData.revenue;
            totalDetailedRevenue += revenue;
            let marketShare =
              Math.round((revenue / marketSize) * 100 * 1e2) / 1e2;
            competitorData.revenue = marketShare;
            const activeCountries =
              competitorData?.activeCountries?.length > 0 &&
              competitorData.activeCountries.join(' / ');

            // console.log('Active Countries -> ', activeCountries);

            let formattedRevenue = formatRevenue(revenue);
            children.push({
              name: competitorData.name,
              image: competitorData.image,
              value: Math.round(marketShare),
              context:
                activeCountries.length > 0
                  ? t('players.chart.detailed_competitor_sales_data_context', {
                      formattedRevenue: formattedRevenue,
                      marketShare: marketShare,
                      activeCountries: activeCountries,
                    })
                  : t(
                      'detailed_competitor_sales_data_context_wo_active_countries',
                      {
                        formattedRevenue: formattedRevenue,
                        marketShare: marketShare,
                      },
                    ),
              color: '#3E3E3E',
            });
            channelRevenue += revenue;
          });
        } else if (channelInfo.type === 'estimate') {
          channelRevenue = Math.round(
            (channelInfo.percentage * marketSize) / 100,
          );
          totalDetailedRevenue += channelRevenue;
        }

        let formattedChannelRevenue = formatRevenue(channelRevenue);
        let channelMarketShare =
          Math.round((channelRevenue / marketSize) * 100 * 1e2) / 1e2;
        channelInfo.value = channelMarketShare;

        dataChildren.push({
          ...channelInfo,
          // context: `Total Revenue: ~${formattedChannelRevenue} | Market Share: ~${channelMarketShare}%`,
          context: t('players.chart.estimate_competitor_sales_data_context', {
            formattedChannelRevenue: formattedChannelRevenue,
            channelMarketShare: channelMarketShare,
          }),
          value: Math.round(channelMarketShare),
          color: channelInfo.color,
          children: children,
        });
      }
    });

    // Calculating the "remainder" type after all the "detailed" and "estimate" types are processed
    competitorSalesData.forEach((channelData) => {
      let channelInfo = Object.values(channelData)[0];
      if (channelInfo.type === 'remainder') {
        channelInfo.revenue = marketSize - totalDetailedRevenue;
        let formattedChannelRevenue = formatRevenue(channelInfo.revenue);
        let channelMarketShare =
          Math.round((channelInfo.revenue / marketSize) * 100 * 1e2) / 1e2;
        dataChildren.push({
          ...channelInfo,
          context: t('players.chart.estimate_competitor_sales_data_context', {
            formattedChannelRevenue: formattedChannelRevenue,
            channelMarketShare: channelMarketShare,
          }),
          value: Math.round(channelMarketShare),
          color: channelInfo.color,
          children: [],
        });

        // Add remainder Revenue to Total
        totalDetailedRevenue += channelInfo.revenue;
      }
    });

    // let overallMarketShare = ((totalDetailedRevenue / marketSize) * 100).toFixed(2);
    let overallMarketShare =
      Math.round((totalDetailedRevenue / marketSize) * 100 * 1e2) / 1e2;
    let formattedTotalRevenue = formatRevenue(totalDetailedRevenue);

    return {
      id: 'marketPlayers',
      description: t('players.content'),
      data: [
        {
          name: t('players.chart.sales_channels.label'),
          value: 100,
          context: t('players.chart.sales_channels.description', {
            formattedTotalRevenue: formattedTotalRevenue,
            overallMarketShare: overallMarketShare,
          }),
          children: dataChildren,
        },
      ],
    };
  }

  function formatRevenue(revenue) {
    let revenueBillions = revenue / 1000000000;
    if (revenueBillions >= 1) {
      return `${revenueBillions.toFixed(1)} Billion`;
    } else {
      return `${(revenue / 1000000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Mio.`;
    }
  }

  // Market Players Chart
  let competitorSalesData = [
    {
      eCommerce: {
        type: 'detailed',
        name: t('players.chart.ecommerce.label'),
        context: t('players.chart.ecommerce.description'),
        color: '#6F7978',
        data: [
          {
            name: 'Automation Direct',
            revenue: 181000000,
            activeCountries: ['US', 'CA', 'MX'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Automation_Direct.svg',
          },
          {
            name: 'Allied',
            revenue: 282500000,
            activeCountries: ['US'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Allied_Logo.svg',
          },
          {
            name: 'Automation24',
            revenue: 5400000,
            activeCountries: ['DE'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Automation24-Logo.svg',
          },
          {
            name: 'McMaster Carr',
            revenue: 662000000,
            activeCountries: ['US', 'CA', 'MX'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/McMaster-Carr-Logo.svg',
          },
          {
            name: t('players.chart.ecommerce.others'),
            activeCountries: [],
            revenue: 400000000,
          },
        ],
      },
    },
    {
      onlineDirectories: {
        type: 'detailed',
        name: t('players.chart.online_directories.label'),
        color: '#B8A5C9',
        draggable: true,
        data: [
          {
            name: 'Thomas Net',
            revenue: 282500000,
            activeCountries: ['US'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Thomas_Net_Logo.svg',
          },
          {
            name: 'Direct Industry',
            revenue: 18600000,
            activeCountries: [
              t('players.chart.online_directories.direct_industry'),
            ],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Direct_Industry_Logo.svg',
          },
          {
            name: 'Radwell',
            revenue: 286000000,
            activeCountries: ['US, DE'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Radwell-Logo.svg',
          },
        ],
      },
    },
    {
      offlinedistributors: {
        type: 'estimate',
        name: t('players.chart.offline_distributors.label'),
        color: '#5779C9',
        percentage: 45,
      },
    },
    {
      direct: {
        type: 'remainder',
        name: t('players.chart.direct_sales.label'),
        color: '#7A579C',
      },
    },
  ];
  const marketPlayers = processCompetitorSalesData(
    competitorSalesData,
    marketSize,
  );

  // Handle Download of Pitchdeck
  const handleDownloadPitchdeck = () => {
    const link = document.createElement('a');
    link.href =
      'https://in2tec.nyc3.cdn.digitaloceanspaces.com/investors/IN2TEC_PitchDeck.pdf';
    link.target = '_blank'; // Open in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container component="main" maxWidth="auto" disableGutters>
      {/* Header */}
      <InvestorHeaderContent leadInfo={leadInfo} />

      {/* Content */}
      <Grid>
        <Grid item container alignItems="flex-start" direction="column">
          {/* The Problem & Solution */}
          <Grid item sx={{ pb: '15rem' }}>
            <WhatMakesUsDifferent
              transKeyPrefix="components.howItWorks.lead_context.investor.whatMakesUsDifferent"
              currentFooterComponent={
                <Grid
                  item
                  container
                  direction="column"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ padding: '1rem 0 1rem 0' }}>
                    <Typography variant="h4">
                      {t('whatMakesUsDifferent.current_footer.title')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    spacing={4}
                    justifyContent="center"
                  >
                    <Grid item xs={12} md={5}>
                      <Typography variant="h6" color="primary.main">
                        {t(
                          'whatMakesUsDifferent.current_footer.column_1.title',
                        )}
                      </Typography>
                      <Typography variant="body">
                        <Trans
                          t={t}
                          i18nKey="whatMakesUsDifferent.current_footer.column_1.description"
                          components={{
                            ul: (
                              <ul
                                style={{
                                  paddingLeft: '1.2rem',
                                  textAlign: 'left',
                                }}
                              />
                            ),
                            li: (
                              <li
                                style={{
                                  wordWrap: 'break-word',
                                  paddingBottom: '0.5rem',
                                }}
                              />
                            ),
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography variant="h6" color="primary.main">
                        {t(
                          'whatMakesUsDifferent.current_footer.column_2.title',
                        )}
                      </Typography>
                      <Typography variant="body">
                        <Trans
                          t={t}
                          i18nKey="whatMakesUsDifferent.current_footer.column_2.description"
                          components={{
                            ul: (
                              <ul
                                style={{
                                  paddingLeft: '1.2rem',
                                  textAlign: 'left',
                                }}
                              />
                            ),
                            li: (
                              <li
                                style={{
                                  wordWrap: 'break-word',
                                  paddingBottom: '0.5rem',
                                }}
                              />
                            ),
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
              intutecFooterComponent={
                <Grid
                  item
                  continer
                  direction="column"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ padding: '1rem 0 1rem 0' }}>
                    <Typography variant="h4">
                      {t('whatMakesUsDifferent.intutec_footer.title')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    spacing={4}
                    justifyContent="center"
                  >
                    <Grid item xs={12} md={5}>
                      <Typography variant="h6" color="primary.main">
                        {t(
                          'whatMakesUsDifferent.intutec_footer.column_1.title',
                        )}
                      </Typography>
                      <Typography variant="body">
                        <Trans
                          t={t}
                          i18nKey="whatMakesUsDifferent.intutec_footer.column_1.description"
                          components={{
                            ul: (
                              <ul
                                style={{
                                  paddingLeft: '1.2rem',
                                  textAlign: 'left',
                                }}
                              />
                            ),
                            li: (
                              <li
                                style={{
                                  wordWrap: 'break-word',
                                  paddingBottom: '0.5rem',
                                }}
                              />
                            ),
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography variant="h6" color="primary.main">
                        {t(
                          'whatMakesUsDifferent.intutec_footer.column_2.title',
                        )}
                      </Typography>
                      <Typography variant="body">
                        <Trans
                          t={t}
                          i18nKey="whatMakesUsDifferent.intutec_footer.column_2.description"
                          components={{
                            ul: (
                              <ul
                                style={{
                                  paddingLeft: '1.2rem',
                                  textAlign: 'left',
                                }}
                              />
                            ),
                            li: (
                              <li
                                style={{
                                  wordWrap: 'break-word',
                                  paddingBottom: '0.5rem',
                                }}
                              />
                            ),
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>

          {/* Players*/}
          <Grid
            item
            container
            alignItems="flex-start"
            direction="column"
            sx={{
              padding: { xs: '10px 0px 50px 0px', md: '50px 0px 50px 20px' },
            }}
          >
            <Grid
              container
              spacing={2}
              alignItems={{ md: 'flex-start' }}
              direction={{ xs: 'column', md: 'row' }}
              sx={{ padding: '0 2rem 0 2rem' }}
            >
              <Grid item xs={12}>
                <Typography variant="h1" color="primary" align="center">
                  {t('players.title')}
                </Typography>
              </Grid>
              {/* Chart */}
              <Grid item xs={12}>
                <ForceDirectedTreeContext
                  description={marketPlayers.description}
                  data={marketPlayers.data}
                  chartId={marketPlayers.id}
                  mobileView={mobileView}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* The Opportunity */}
          <Grid
            item
            container
            alignItems="flex-start"
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              margin: '5rem 0 5rem 0',
              padding: '0px 2rem 0 2rem',
            }}
          >
            <Grid
              container
              spacing={2}
              direction={{ xs: 'column', md: 'row' }}
              sx={{ padding: '0 2rme 0 2rem' }}
            >
              <Grid item xs={12}>
                <Typography variant="h1" color="primary" textAlign="center">
                  {t('potential.title')}
                </Typography>
              </Grid>

              {/* Description & Calculator */}
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignContent="center"
                justifyContent="center"
                textAlign="center"
              >
                <Grid item xs={12}>
                  <Typography variant="body" paragraph>
                    <Trans t={t} i18nKey="potential.content" />
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: '5rem' }}>
                  <MapChartContext
                    chartId="marketSizeChart"
                    data={marketSizeChart(t).data}
                    description={marketSizeChart(t)?.description}
                    mobileView={mobileView}
                  />
                </Grid>

                <Grid item xs={12} md={8} sx={{ marginBottom: '5rem' }}>
                  <RevenueModel />
                </Grid>

                {/* Revenue Calculator */}
                <Grid item xs={12} md={8}>
                  <RevenueCalculator />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Current Customers & Business */}
          <Grid
            item
            container
            alignItems="flex-start"
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              margin: '5rem 0 5rem 0',
              padding: '0px 2rem 0 2rem',
            }}
          >
            <Grid container spacing={2} direction={{ xs: 'column', md: 'row' }}>
              <Grid item xs={12}>
                <Typography variant="h1" color="primary" textAlign="center">
                  {t('customers.title')}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                xs={12}
              >
                {t('customers.description')}
              </Grid>

              {/* Carousel */}
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                xs={12}
              >
                <BrandsInfiniteSliderContainer initialDelay={2} />
              </Grid>
            </Grid>
          </Grid>

          {/* About the Founders */}
          <Grid
            item
            container
            alignItems="flex-start"
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              margin: '5rem 0 5rem 0',
              padding: '0px 2rem 0 2rem',
            }}
          >
            <Grid container spacing={2} direction={{ xs: 'column', md: 'row' }}>
              <Grid item xs={12}>
                <Typography variant="h1" color="primary" textAlign="center">
                  {t('founders.title')}
                </Typography>
              </Grid>
              {/* Team */}
              <Grid
                item
                container
                spacing={10}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                xs={12}
              >
                {/* Mathias */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  xs={12}
                  md={4}
                >
                  {/* Title */}
                  <Typography variant="h5">
                    <Trans t={t} i18nKey="founders.mathias.title" />
                  </Typography>

                  {/* Contact  */}
                  <Grid item sx={{ margin: '0rem 0 2rem 0' }}>
                    <Typography variant="body1">
                      {transCommon('phone')}: +1 (650) 561 5752
                    </Typography>
                    <Typography variant="body1">
                      {transCommon('email')}: mk@intutec.io
                    </Typography>
                  </Grid>

                  {/* Avatar */}
                  <Grid item sx={{ paddingBottom: '20px' }}>
                    <Avatar
                      alt="Mathias Krostewitz"
                      src={mathiasKrostewitz}
                      sx={{
                        width: 300,
                        height: 300,
                        border: `0.1px solid ${intuMiddleGrey}`,
                      }}
                    />
                  </Grid>
                  {/* About */}
                  <Grid item>
                    <Typography variant="body">
                      <Trans t={t} i18nKey="founders.mathias.description" />
                    </Typography>
                  </Grid>
                </Grid>

                {/* Phil */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  xs={12}
                  md={4}
                >
                  {/* Title */}
                  <Typography variant="h5">
                    <Trans t={t} i18nKey="founders.phil.title" />
                  </Typography>

                  {/* Contact  */}
                  <Grid item sx={{ margin: '0rem 0 2rem 0' }}>
                    <Typography variant="body1">
                      {transCommon('phone')}: +1 (347) 982 2654
                    </Typography>
                    <Typography variant="body1">
                      {transCommon('email')}: po@intutec.io
                    </Typography>
                  </Grid>

                  {/* Avatar */}
                  <Grid item sx={{ paddingBottom: '20px' }}>
                    <Avatar
                      alt="Phil Oh"
                      src={philOh}
                      sx={{
                        width: 300,
                        height: 300,
                        border: `0.1px solid ${intuMiddleGrey}`,
                      }}
                    />
                  </Grid>

                  {/* About */}
                  <Grid item xs={12}>
                    <Typography variant="body">
                      <Trans t={t} i18nKey="founders.phil.description" />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Download The Pitchdeck */}
          <Grid
            item
            container
            xs={12}
            direction="column"
            textAlign="center"
            justifyContent="center"
            alignContent="center"
            spacing={4}
            sx={{ padding: '5rem 0 5rem 0' }}
          >
            <Grid item xs={12}>
              <Typography variant="h1">{t('pitchdeck.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  cursor: 'pointer', // Makes the image appear clickable
                  display: 'inline-block', // Keeps layout inline if needed
                }}
                onClick={handleDownloadPitchdeck} // Attach the click handler
              >
                <Box
                  component="img"
                  sx={{
                    width: { xs: 350, md: '80vw' },
                    // maxHeight: { xs: 233, md: '50vh' },
                    maxWidth: { xs: 350, md: '40vw' },
                  }}
                  alt="PitchDeck"
                  src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/investors/PitchDeck.png"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadPitchdeck}
              >
                {t('pitchdeck.button.label')}
              </Button>
            </Grid>
          </Grid>

          {/* Contact Us */}

          <Grid
            item
            container
            direction="column"
            textAlign="center"
            spacing={4}
            sx={{ padding: '0 1rem 0 1rem' }}
          >
            <Grid item>
              <Typography variant="h1">
                Learn more about the Opportunity
              </Typography>
            </Grid>

            <Grid
              item
              container
              spacing={2}
              direction="row"
              sx={{ marginBottom: '3rem' }}
            >
              {/* Call Us */}
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<Call sx={{ color: 'primary.main' }} />}
                  sx={{
                    width: '200px',
                  }}
                  onClick={() => (window.location.href = 'tel:+1-650-212-6155')}
                >
                  +1 (650) 220 1338
                </Button>
              </Grid>

              {/* Schedule a Meeting */}
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="info"
                  startIcon={<CalendarIcon sx={{ color: 'info.main' }} />}
                  sx={{
                    width: '200px',
                  }}
                  onClick={() =>
                    window.open(
                      'https://meetings.hubspot.com/mathias-krostewitz',
                      '_blank',
                    )
                  }
                  target="_blank"
                >
                  {t('contact.meeting')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export { InvestorContent };
