import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Skeleton,
  useTheme,
  Avatar,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useContext } from 'react';
import ClusteredMap from '../../components/maps/ClusteredMap';
import { useParams } from 'react-router-dom';
import { CustomerMapContext } from '../providers/maps/CustomerMapProvider';
import { UserPublicProfileContext } from '../providers/profile/UserPublicProfileProvider';
import { useProcessingHandler } from '../../hooks/useProcessingHandler';
import { ProfileHeaderComponent } from '../../components/profile/ProfileHeaderComponent';
import { ProfileAnalyticsChart } from '../../components/profile/ProfileAnalyticsChart';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import IntuThemeContext from '../providers/IntuThemeProvider';
import RecruitDialog from '../../components/profile/RecruitDialog';
import { PublicProfileContext } from '../providers/profile/PublicProfileProvider';
import { useNavigationFooter } from '../footer/NavigationFooterContext';

export const UserPublicProfileContent = () => {
  const {
    getUserInfo,
    handleSearchForAssociatedCustomers,
    columns,
    getBrandParentCompanyInfo,
  } = useContext(UserPublicProfileContext);
  const {
    requestToRecruit,
    handleSearchOffers,
    handleSearchOrders,
    formatDataByMonth,
    orders,
    offers,
    isChartLoading,
    setIsChartLoading,
    isLoadingMap,
    setIsLoadingMap,
    activeChartData,
    setActiveChartData,
    chartSettings,
    orderChartData,
    setOrderChartData,
    offerChartData,
    setOfferChartData,
    updateChartData,
    isLoadingOrders,
    setIsLoadingOrders,
    getRelationships,
  } = useContext(PublicProfileContext);
  const { center, zoomLevel, setMapCenterAndZoom } =
    useContext(CustomerMapContext);
  const { id } = useParams();

  const [currentUserInfo, setCurrentUserInfo] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const { setIsLoading } = useProcessingHandler();
  const [userMiscInfo, setUserMiscInfo] = useState({
    member_since: '',
    location: '',
    brands: 0,
    sales_yoy: 0,
  });
  const [isRecruitDialogOpen, setIsRecruitDialogOpen] = useState(false);
  const [userBrands, setUserBrands] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const { mobileView, mode } = useContext(IntuThemeContext);
  const theme = useTheme();
  const { setFooterConfig } = useNavigationFooter();

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.PublicProfile',
  });

  // Setup navigation footer
  useEffect(() => {
    setFooterConfig({
      showFooter: true,
    });

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  // Grab user's profile using ID param
  async function fetchUserInfo(id) {
    const userInfo = await getUserInfo(id);
    setCurrentUserInfo(userInfo);
  }

  // Grab customers associated to user
  async function getCustomerList(arr) {
    const orderCustomers = arr?.map((order) => {
      return order.customer;
    });
    const uniqueCustomers = [...new Set(orderCustomers)];
    const customers = await handleSearchForAssociatedCustomers(uniqueCustomers);
    const filteredCustomers = customers?.filter(
      (customer) =>
        customer.location !== null &&
        customer.location !== undefined &&
        customer.location !== '',
    );
    setCustomerList(filteredCustomers);
  }

  // Preload Info
  useEffect(() => {
    preLoadInfo();
  }, [isLoadingOrders]);

  async function preLoadInfo() {
    setIsLoading({
      status: true,
      text: `Loading ${currentUserInfo.name}'s Profile`,
      type: 'spinner',
    });
    await fetchUserInfo(id);
    await handleSearchOrders(id, 'associate');
    setIsLoadingOrders(false);
    await handleSearchOffers(id, 'associate');
    if (!isLoadingOrders) {
      await getCustomerList(orders);
    }
    setIsLoading({
      status: false,
      text: '',
      type: 'spinner',
    });
  }

  function setMiscInfo() {
    const start_date = new Date(currentUserInfo?.created?.date);
    const user_location = {
      city: currentUserInfo?.address?.city,
      state: currentUserInfo?.address?.state,
    };
    const total_revenue = getTotalYearRevenue().toFixed(2);
    setUserMiscInfo({
      ...userMiscInfo,
      member_since: start_date.toLocaleDateString(),
      location: user_location,
      sales_yoy: '$' + Number(total_revenue).toLocaleString(),
      brands: currentUserInfo?.brands?.length,
    });
  }

  function getPastYear(arr) {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 12);
    const filtered = arr?.filter((item) => {
      const itemDate = new Date(item.created.date);
      return itemDate >= sixMonthsAgo;
    });
    return filtered;
  }

  function getTotalYearRevenue() {
    let total = 0;
    const pastYearOrders = getPastYear(orders);
    pastYearOrders.forEach((order) => {
      total += order.grand_total;
    });
    return total;
  }

  // Load DataGrid Info
  useEffect(() => {
    async function loadDataGridInfo() {
      const associateRelationships = await getRelationships(id, 'associate');
      const companyRelationships = associateRelationships?.map(
        (relationship) => {
          return relationship.manufacturer;
        },
      );
      if (companyRelationships?.length > 0) {
        const companies = await getBrandParentCompanyInfo(companyRelationships);
        setUserBrands(companies);
      }
    }
    setMiscInfo();
    loadDataGridInfo();
  }, [currentUserInfo, orders]);

  // Format and Set Chart Data
  useEffect(() => {
    setIsChartLoading(true);
    // Format Order Data By Month for Bar Chart
    const pastYearOrders = getPastYear(orders);
    const orderSalesData = formatDataByMonth(pastYearOrders, 'order');
    updateChartData(orderSalesData, 'order', t('revenue'), setOrderChartData);

    const pastYearOffers = getPastYear(offers);
    const offerData = formatDataByMonth(pastYearOffers, 'offer');
    updateChartData(offerData, 'offer', t('offers'), setOfferChartData);

    setIsChartLoading(false);
  }, [orders, offers, i18n.language]);

  let coordinatesArr = [];

  const clusteredMapData = customerList
    ?.filter((customer) => customer.industry !== null)
    .map((customer) => {
      coordinatesArr.push(customer.location);
      return {
        name: customer.first_name + ' ' + customer.last_name,
        content: customer.industry,
        category: customer.industry,
        coordinates: customer.location,
      };
    });

  // Preload Map and Chart Data
  useEffect(() => {
    setIsLoadingMap(true);
    setBarChartData(salesData);
    if (!coordinatesArr.length) {
      setMapCenterAndZoom([{ lat: 40.7128, lng: -74.006 }]);
    } else {
      setMapCenterAndZoom(coordinatesArr);
    }
    setIsLoadingMap(false);
  }, [customerList]);

  const handleConfirm = async (newApplication) => {
    await requestToRecruit(newApplication, id, currentUserInfo.name);
  };

  return (
    <Grid container>
      {/* Header */}
      <ProfileHeaderComponent
        profileInfo={currentUserInfo}
        miscInfo={userMiscInfo}
        setIsDialogOpen={() => setIsRecruitDialogOpen(true)}
        type="sales"
      />
      {/* Main Content */}
      <Grid container item flexDirection="column" xs={12} mt={4}>
        {/* Languages */}
        {currentUserInfo?.languages && (
          <>
            <Grid item mb={4}>
              <Typography variant="h5">{t('languages')}</Typography>
            </Grid>
            <Grid
              item
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              pb={4}
            >
              {currentUserInfo?.languages?.map((language) => {
                return (
                  <>
                    <Grid item key={language.flagCode}>
                      <Avatar
                        src={`https://flagcdn.com/w80/${language.flagCode.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w160/${language.flagCode.toLowerCase()}.png 2x`}
                        alt=""
                        sx={{
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        }}
                      />
                    </Grid>
                    <Grid item pl={1} pr={4} key={language.name}>
                      <Typography>{language.name}</Typography>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </>
        )}
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-around' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          {/* Areas Served */}
          <Grid container item md={5.95} flexDirection="column">
            <Box
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: '16px',
                backgroundColor: theme.palette.profile[mode],
              }}
            >
              <Grid item>
                {isLoadingMap ? (
                  <Grid>
                    <Skeleton variant="rectangular" height={500} />
                  </Grid>
                ) : (
                  <ClusteredMap
                    locations={clusteredMapData || []}
                    initialZoom={zoomLevel}
                    popup={true}
                    cluster={false}
                    projection="globe"
                    center={center}
                    disableBorder
                    isDataLoaded={isLoadingMap}
                    rounded
                  />
                )}
              </Grid>
            </Box>
          </Grid>
          {/* Analytics */}
          <Grid
            container
            item
            md={5.95}
            flexDirection="column"
            justifyContent="center"
          >
            <ProfileAnalyticsChart
              activeChartData={activeChartData}
              setActiveChartData={setActiveChartData}
              isChartLoading={isChartLoading}
              chartSettings={chartSettings}
              mobileView={mobileView}
              data1={orderChartData}
              data2={offerChartData}
              data={[]}
              type="sales"
            />
          </Grid>
        </Grid>

        {/* Current Brands */}
        <Grid item mt={4}>
          <Typography variant="h5">{t('brands')}</Typography>
        </Grid>
        <Box sx={{ height: '500px', width: '100%' }}>
          <DataGrid
            rows={userBrands}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 3,
                },
              },
            }}
            pageSizeOptions={[3]}
            disableRowSelectionOnClick
            sx={{
              '.MuiDataGrid-columnHeader': {
                backgroundColor: 'transparent',
                color: 'white',
              },
              width: '100%',
            }}
            columnVisibilityModel={{
              region: !mobileView,
              start_date: !mobileView,
              products: !mobileView,
            }}
            getRowHeight={() => 'auto'}
            autoHeight
            autosizeOnMount
          />
        </Box>
      </Grid>
      {Object.keys(currentUserInfo).length ? (
        <RecruitDialog
          isOpen={isRecruitDialogOpen}
          handleClose={() => setIsRecruitDialogOpen(false)}
          handleConfirm={handleConfirm}
          userInfo={currentUserInfo}
        />
      ) : null}
    </Grid>
  );
};
