import { useCallback, useContext, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { convertFileToBase64 } from '../../helpers/convertFileToBase64';
import { useAxiosPrivateData } from '../../hooks/axios/useAxiosPrivate';
import IntuIconButton from '../buttons/IntuIconButton';
import CanvasContainer from './CanvasContainer';
import CloseIcon from '@mui/icons-material/Close';
import StarsIcon from '@mui/icons-material/Stars';
import ReplayIcon from '@mui/icons-material/Replay';
import { ProductMeshContext } from './context/ProductMeshContext';
import ResponseContext from '../../context/providers/ResponseProvider';

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  minHeight: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const UploadImageDialog = ({
  isOpen,
  handleConfirm,
  handleConfirmEdit,
  handleClose,
  productId,
  editingImage,
  handleDeleteImage,
  handleSetDefaultImage,
}) => {
  const { t: transButtons } = useTranslation('buttons');
  const axiosData = useAxiosPrivateData();
  const {
    meshes,
    setMeshes,
    initialMeshes,
    isProductImageDirtied,
    setIsProductImageDirtied,
    setRotationX,
    setRotationY,
    setCameraPosition,
  } = useContext(ProductMeshContext);
  const { setStatusMsg } = useContext(ResponseContext);

  const [image, setImage] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [remoteUrl, setRemoteUrl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleNewImage = async (file, fileType) => {
    try {
      setIsSaving(true);
      const base64File = await convertFileToBase64(file);
      let url = '';
      let payload = {
        file: base64File,
      };
      if (fileType === 'stp' || fileType === 'STEP') {
        payload = {
          ...payload,
          fileType,
          fileName: file.name,
        };
        url = `/api/file-processor/process-step/${productId}`;
      }

      if (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg') {
        payload = {
          ...payload,
          file_type: fileType,
          file_name: file.name,
          folder: `products/${productId}/images`,
        };
        url = '/api/aws/content/add';
      }

      const { data, status } = await axiosData.post(
        url,
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 200) {
        setRemoteUrl(data.data);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setFileName(file.name);
      setIsSaving(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileType = acceptedFiles[0].name.substring(
        acceptedFiles[0].name.lastIndexOf('.') + 1,
        acceptedFiles[0].name.length,
      );

      // Check if the file size exceeds 30 MB
      const maxSizeInBytes = 30 * 1024 * 1024; // 30 MB in bytes
      if (file.size > maxSizeInBytes) {
        console.error('File size exceeds 30 MB');
        setStatusMsg({
          open: true,
          status: 'error',
          message: 'File size exceeds 30 MB',
        });
        return;
      }

      if (fileType !== 'stp') {
        setPreviewURL(URL.createObjectURL(acceptedFiles[0]));
      }
      setImage(acceptedFiles[0]);
      setImageType(fileType);
      handleNewImage(acceptedFiles[0], fileType);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const saveModelChanges = async (updatedMeshes) => {
    try {
      setIsSaving(true);

      const payload = {
        meshes: updatedMeshes,
        fileName,
      };

      const { data, status } = await axiosData.post(
        `/api/file-processor/update-gltf/${productId}`,
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 200) {
        handleConfirmEdit(data.data, editingImage);
        setIsProductImageDirtied(false);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleConfirmClick = () => {
    if (!isProductImageDirtied) {
      handleConfirm(remoteUrl);
    } else {
      const updatedMeshes = meshes.map((mesh) => ({
        name: mesh.name,
        color: mesh.material.color.getHex(),
        roughness: mesh.material.roughness,
        metalness: mesh.material.metalness,
        positions: Array.from(mesh.geometry.attributes.position.array),
        normals: Array.from(mesh.geometry.attributes.normal.array),
        indices: Array.from(mesh.geometry.index.array),
      }));
      saveModelChanges(updatedMeshes);
    }

    setImage(null);
    setImageType(null);
    setRemoteUrl(null);
    setPreviewURL(null);
  };

  const handleCloseClick = () => {
    handleClose();
    setImage(null);
    setImageType(null);
    setPreviewURL(null);
    setRemoteUrl(null);
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageType(null);
    setPreviewURL(null);
    setRemoteUrl(null);
    handleDeleteImage(remoteUrl || editingImage);
  };

  const handleResetImage = () => {
    setMeshes((prevMeshes) => {
      return prevMeshes.map((mesh, index) => {
        // Get the corresponding initial state
        const initialState = initialMeshes[index];

        // Reset the mesh's material properties using the stored hex color
        mesh.material.color.setHex(initialState.color);
        mesh.material.roughness = initialState.roughness;
        mesh.material.metalness = initialState.metalness;

        return mesh;
      });
    });

    setRotationX(0);
    setRotationY(0);
    setCameraPosition([0, 0, 100]);

    // Reset the dirty state since we've restored to initial values
    setIsProductImageDirtied(false);
  };

  const handleDefaultClick = () => {
    handleSetDefaultImage(editingImage || remoteUrl);
  };

  const editingImageType = useMemo(() => {
    if (!editingImage) return null;
    const fileType = editingImage.substring(
      editingImage.lastIndexOf('.') + 1,
      editingImage.length,
    );
    return fileType;
  }, [editingImage]);

  const isConfirmDisabled = useMemo(() => {
    if (editingImage && isProductImageDirtied) return false;

    if (editingImage && !isProductImageDirtied) return true;

    if (!image && !imageType) return true;

    if (isSaving) return true;

    return false;
  }, [editingImage, isProductImageDirtied, image, imageType, isSaving]);

  const isRemoveDisabled = useMemo(() => {
    if (editingImage) return false;

    if (remoteUrl && image) return false;

    return true;
  }, [editingImage, remoteUrl, image]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          textTransform: 'none',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        Upload Product Images (.stp, .STEP, .png, .jpeg)
        <IntuIconButton
          size="small"
          variant="outlined"
          tooltipTitle={transButtons('close')}
          onClick={handleCloseClick}
          IconComponent={CloseIcon}
        />
      </DialogTitle>
      <DialogContent
        style={{
          paddingTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          width: '80%',
        }}
      >
        {!previewURL && !remoteUrl && !isSaving && !editingImage ? (
          <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the file here...</Typography>
            ) : (
              <Typography>
                Drag and drop an image or stp file here, or click to select a
                image
              </Typography>
            )}
          </div>
        ) : null}
        {image ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography>{image.name}</Typography>
          </Box>
        ) : null}
        {isSaving ? <CircularProgress /> : null}
        <Box display="flex" flexDirection="column" width="100%">
          {previewURL &&
          (imageType === 'png' ||
            imageType === 'jpeg' ||
            imageType === 'jpg') ? (
            <Box margin="0 auto" width="100%" maxWidth="500px" height="auto">
              <Box
                component="img"
                src={`${previewURL}#page=1`}
                alt="product preview image"
                sx={{
                  display: 'block',
                  margin: '0 auto',
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  maxWidth: '500px',
                  maxHeight: '500px',
                }}
              />
            </Box>
          ) : remoteUrl && (imageType === 'stp' || imageType === 'STEP') ? (
            <Box>
              <CanvasContainer image={remoteUrl} viewMode="edit" />
            </Box>
          ) : editingImage && editingImageType === 'gltf' ? (
            <Box>
              <CanvasContainer image={editingImage} viewMode="edit" />
            </Box>
          ) : editingImage &&
            (editingImageType === 'png' ||
              editingImageType === 'jpeg' ||
              editingImageType === 'jpg') ? (
            <Box margin="0 auto" width="100%" maxWidth="500px" height="auto">
              <Box
                component="img"
                src={`${editingImage}#page=1`}
                alt="product preview image"
                sx={{
                  display: 'block',
                  margin: '0 auto',
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  maxWidth: '500px',
                  maxHeight: '500px',
                }}
              />
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-between',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingBottom: '16px',
        }}
      >
        <Box>
          <IntuIconButton
            size="small"
            type="error"
            variant="outlined"
            tooltipTitle={transButtons('remove')}
            disabled={isRemoveDisabled}
            onClick={handleRemoveImage}
          />
          <IntuIconButton
            size="small"
            type="connect"
            variant="outlined"
            tooltipTitle={transButtons('reset')}
            onClick={handleResetImage}
            disabled={!isProductImageDirtied}
            IconComponent={ReplayIcon}
          />
        </Box>
        <Box>
          <IntuIconButton
            size="small"
            variant="outlined"
            tooltipTitle={transButtons('default')}
            onClick={handleDefaultClick}
            disabled={!remoteUrl && !editingImage}
            IconComponent={StarsIcon}
          />
          <IntuIconButton
            size="small"
            type="submit"
            variant="outlined"
            tooltipTitle={transButtons('confirm')}
            onClick={handleConfirmClick}
            disabled={isConfirmDisabled}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UploadImageDialog;
