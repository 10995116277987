import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const NavigationFooter = () => {
  const navigate = useNavigate();
  const { footerConfig } = useNavigationFooter();
  const { t: transButtons } = useTranslation('buttons');
  const {
    confirmLabel,
    ConfirmIcon,
    onConfirm,
    isLoading,
    isDisabled,
    showFooter,
    showConfirm,
  } = footerConfig;

  const handleBack = () => {
    if (footerConfig.onBack) {
      footerConfig.onBack();
    }
    navigate(-1);
  };

  if (!showFooter) return null;

  return (
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        backgroundColor: 'background.dark',
        height: 60,
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          minHeight: 40,
          height: 40,
          px: 1,
        }}
      >
        <Button
          variant="outlined"
          onClick={handleBack}
          size="small"
          startIcon={<ArrowBackIosIcon fontSize="small" />}
          sx={{
            width: 200,
            color: 'common.white',
            borderColor: 'common.white',
            height: 30,
          }}
        >
          {transButtons('back')}
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {showConfirm ? (
          <LoadingButton
            variant="outlined"
            color="primary"
            onClick={onConfirm}
            loading={isLoading}
            disabled={isDisabled}
            endIcon={
              ConfirmIcon ? (
                <ConfirmIcon
                  sx={{ color: isDisabled ? '#2c2c2e' : 'common.white' }}
                />
              ) : null
            }
            size="small"
            sx={{
              width: 200,
              height: 30,
              color: 'common.white',
              borderColor: 'common.white',
            }}
          >
            {confirmLabel}
          </LoadingButton>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default NavigationFooter;
