import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { SignUpForm } from '../../components/account/forms/signup/SignUpForm';
import IntuPage from '../../components/layout/IntuPage';
import './account.css';

function AccountSignUp() {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.account.SignUp.title')}
      pageDescription={t('pages.account.SignUp.description')}
      hCentered={true}
      vCentered={true}
    >
      <IntuForm hCentered={true} vCentered={true} form="SignUp">
        <SignUpForm />
      </IntuForm>
    </IntuPage>
  );
}
export default AccountSignUp;
