import { Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Balance, Warehouse } from '@mui/icons-material';

const AdditionalServicesComponent = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.subscriptions.additional_services',
  });

  const cardStyles = {
    borderRadius: '10px',
    boxShadow: 3,
    border: '1px solid #e0e0e0',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '400px', // Ensures cards have consistent height
  };

  const cardContentStyles = {
    padding: 3,
    flexGrow: 1, // Allows the content to expand and fill the available space
    overflow: 'hidden', // To handle text overflow
    textOverflow: 'ellipsis',
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      textAlign="center"
      sx={{ mb: '10rem' }}
    >
      <Grid
        item
        xs={4}
        spacing={4}
        container
        direction="column"
        textAlign="center"
        sx={{ mb: '5rem' }}
      >
        {/* Title */}
        <Grid item>
          <Typography variant="h4">{t('title')}</Typography>
        </Grid>

        {/* Warehouse Services */}
        <Grid item>
          <Warehouse sx={{ fontSize: '5rem' }} />
          <Typography variant="h6">{t('warehouse.title')}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey="warehouse.description"
              components={{
                br: <br />,
                li: <li />,
                ul: <ul />,
              }}
            />
          </Typography>
        </Grid>

        {/* Legal & Accounting Services */}
        <Grid item>
          <Balance sx={{ fontSize: '5rem' }} />
          <Typography variant="h6">{t('legal_accounting.title')}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey="legal_accounting.description"
              components={{
                br: <br />,
                li: <li />,
                ul: <ul />,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalServicesComponent;
