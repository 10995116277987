import { Grid } from '@mui/material';
import './aboutCompany.css';
import IntuPage from '../../components/layout/IntuPage';
import PageHeader from '../../components/layout/PageHeader';
import { AboutCompanyHeaderContent } from '../../components/aboutus/AboutCompanyHeaderContent';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';
import { useContext } from 'react';
import AboutUsComponent from '../../components/aboutus/aboutUsComponent';

const AboutCompany = () => {
  const { mobileView } = useContext(IntuThemeContext);

  return (
    <Grid>
      <Grid item xs={12} sx={{ marginBottom: '2rem' }}>
        <PageHeader body={<AboutCompanyHeaderContent />} />
      </Grid>
      <Grid item>
        <AboutUsComponent />
      </Grid>
    </Grid>
  );
};

export default AboutCompany;
