import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import useIntuTheme from '../../../hooks/useIntuTheme.js';
import { useTranslation } from 'react-i18next';
import { SimpleZoomInOutcomponent } from '../../motion/SimpleZoomInOut.jsx';

export const WhatMakesUsDifferent = ({
  title,
  description,
  transNS = 'translation',
  transKeyPrefix = 'components.howItWorks.lead_context.manu.whatMakesUsDifferent',
  company = null,
  currentFooterComponent = null,
  intutecFooterComponent = null,
}) => {
  const theme = useTheme();

  const { t } = useTranslation(transNS, {
    keyPrefix: transKeyPrefix,
  });

  const { t: transCommon } = useTranslation('common');
  const { mode, mobile } = useIntuTheme();
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        // padding: { xs: '2rem 0 0 0', md: '5rem 2rem 0 2rem' },
        maxWidth: { xs: '100%', md: '80%' },
        margin: '0 auto',
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          sx={{
            wordBreak: 'break-word',
            margin: { xs: '0 1rem', md: '0' },
          }}
        >
          {title}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          wordBreak: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        }}
      >
        <Typography variant="h5" align="center">
          {description}
        </Typography>
      </Grid>

      {/* Current */}
      <Grid item>
        <SimpleZoomInOutcomponent
          srcArray={[
            {
              type: 'animation',
              stateMachines: 'State Machine 1',
              src:
                mode === 'dark'
                  ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/current_tree_dark_mode.riv'
                  : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/current_tree_light_mode.riv',
              title: t('current_flow.title', {
                company: company ? `${company}'s` : transCommon('your'),
              }),
              description: t('current_flow.description', {
                company: company ? `${company}'s` : transCommon('your'),
              }),
              progress: [0.5, 0.8, 1],
              zoom: [0, 0.5, 1],
              stickyval: mobile ? 0.8 : 0.5,
              unstickyval: 0.9,
              titleMT: 0,
              titleMB: 0,
              width: small ? '90vw' : medium ? '500px' : '800px',
              height: small ? '90vw' : medium ? '550px' : '900px',
              customLabels: {
                ECommerce: transCommon('eCommerce'),
                Manufacturer: company ? company : transCommon('manufacturer'),
                ManufacturerRep: transCommon('manufacturer_rep'),
                Distributor1: transCommon('distributor_local'),
                Distributor2: transCommon('distributor_local'),
                SubDistributor: transCommon('distributor_sub'),
                MasterDistributor: transCommon('distributor_master'),
                IndependentSalesRep1: transCommon('IndependentSalesRep'),
                IndependentSalesRep2: transCommon('IndependentSalesRep'),
                Customer1: transCommon('customer'),
                Customer2: transCommon('customer'),
                Customer3: transCommon('customer'),
                Customer4: transCommon('customer'),
                Customer5: transCommon('customer'),
                Customer6: transCommon('customer'),
              },
            },
          ]}
        />
      </Grid>

      <Grid item xs={12} textAlign="center" sx={{ padding: '0 1rem 0 1rem' }}>
        {currentFooterComponent}
      </Grid>

      {/* How we operate your brand */}
      <Grid item>
        <SimpleZoomInOutcomponent
          srcArray={[
            {
              type: 'animation',
              stateMachines: 'State Machine 1',
              src:
                mode === 'dark'
                  ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_flow_dark_mode.riv'
                  : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_flow_light_mode.riv',
              title: t('intutec_flow.title', {
                company: company ? `${company}'s` : transCommon('your'),
              }),
              description: t('intutec_flow.description', {
                company: company ? `${company}'s` : transCommon('your'),
              }),
              progress: [0.5, 0.8, 1],
              zoom: [0, 0.5, 1],
              stickyval: 0.6,
              unstickyval: 1,
              titleMT: '10%',
              titleMB: 0,
              width: small ? '90vw' : medium ? '500px' : '800px',
              height: small ? '90vw' : medium ? '500px' : '900px',
              customLabels: {
                Manufacturer: company ? company : transCommon('manufacturer'),
                ProductReferals: transCommon('product_referals'),
                ActiveMarketing: transCommon('active_marketing'),
                ByIndustry: transCommon('by_industry'),
                ByIndustryProfessionals: transCommon(
                  'by_industry_professsionals',
                  {
                    ns: 'common',
                  },
                ),
                SalesEngineers: transCommon('sales_engineers'),
                Customers: transCommon('customers'),
                ByProductType: transCommon('by_product_type'),
                ByState: transCommon('by_state'),
                LeadsAndCustomerData: transCommon('leads_customer_data', {
                  ns: 'common',
                }),
              },
            },
          ]}
        />
      </Grid>

      <Grid item xs={12} textAlign="center" sx={{ padding: '0 1rem 0 1rem' }}>
        {intutecFooterComponent}
      </Grid>
    </Grid>
  );
};
