export const blankLeadForm = ({ lng = 'en' }) => {
  return {
    active: false,
    created: '',
    clicked: '',
    first_name: '',
    last_name: '',
    company: '',
    lead_type: '',
    company_type: 'company',
    email: '',
    status: '',
    website: '',
    intro_type: '',
    intro_subject: '',
    intro_message: '',
    headline_title: '',
    headline_body: '',
    language: lng,
    origin: '',
    send_email: false,
    industries: [],
    activity: [],
    associate: {
      _id: '',
      name: '',
      email: '',
    },
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postcode: '',
      country: {
        label: 'United States',
        dialCode: '+1',
        languageCode: '',
        code: 'US',
      },
      coordinates: { latitude: '', longitude: '' },
    },
    phone: {
      value: '',
      info: {
        countryCallingCode: '1',
        countryCode: 'US',
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    },
    commissions: [],
    commission_schedule: [],
    company_banner: '',
    company_banner_url: '',
    company_banner_blob: '',
    associate_activity: [],
  };
};
