// THE USER INFO PROVIDER HOLDS PUBLIC USER INFORMATION e.g.
// - Ip Addres
// - Cookie Settings
// - Location Details
// - Language Details
//
// ONLY ADD Features and functions which do not involve personal user information

import { createContext, useState, useEffect, useContext } from 'react';
import axios from '../../hooks/axios/axios';
import { v4 as uuid } from 'uuid';
import { getCookieConsentValue } from 'react-cookie-consent';
import countryList from '../../data/CountryData.json';
import i18n from '../../i18n';
import { initGA } from '../../ga-utils';
import LanguagesData from '../../data/LanguagesData.json';
import AuthContext from './AuthProvider';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';

const UserContext = createContext({});

//Get Current Users Position for Address Search
async function getCoordinates() {
  const countryOptions = countryList;

  return await axios
    .get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO_TOKEN}`)
    .then((response) => {
      const countryDetails = countryOptions.filter(
        (country) => country.code === response.data.country,
      );
      const locationDetails = {
        dialCode: '+' + countryDetails[0].dialCode,
        countryLabel: countryDetails[0].label,
      };

      let locationData = response.data.loc;
      let positionArray = locationData.split(',');
      let location = {
        longitude: positionArray[1],
        latitude: positionArray[0],
        ip: response.data.ip,
        city: response.data.city,
        state: response.data.region,
        country: response.data.country,
        countryLabel: locationDetails?.countryLabel,
        postcode: response.data.postal,
        dialCode: `+${locationDetails?.dialCode}`,
        countryCallingCode: locationDetails?.dialCode,
      };

      return location;
    })
    .catch((e) => {
      return {
        longitude: '74.0060',
        latitude: '40.7128',
        ip: '8.8.8.8',
        city: 'New York',
        state: 'NY',
        country: 'US',
        countryLabel: 'United States',
        dialCode: '+1',
        postcode: '10014',
        countryCallingCode: '1',
      };
    });
}

const getUserCookieSettings = () => {
  // check if cookie settings exist
  let cookieSettings = getCookieConsentValue('intu_cookie_settings');
  let cookieObject = { auth: true, exp: true, trck: true };

  if (!cookieSettings) {
    return cookieObject;
  } else {
    // convet it to JSON Object
    return (cookieObject = cookieSettings.split(';').reduce((res, c) => {
      const [key, val] = c.trim().split('=').map(decodeURIComponent);
      const allNumbers = (str) => /^\d+$/.test(str);
      try {
        return Object.assign(res, {
          [key]: allNumbers(val) ? val : JSON.parse(val),
        });
      } catch (e) {
        return Object.assign(res, { [key]: val });
      }
    }, {}));
  }
};

export const UserProvider = ({ children }) => {
  const userCookieSettings = getUserCookieSettings();
  const { auth } = useContext(AuthContext);
  const [languages, setLanguages] = useState(LanguagesData);
  const [cookieSettings, setCookieSettings] = useState(userCookieSettings);
  const [user, setUser] = useState({
    location: {
      longitude: '',
      latitude: '',
      ip: '',
      city: '',
      state: '',
      country: '',
      countryLabel: '',
      dialCode: '',
      postcode: '',
      countryCallingCode: '',
    },
    sessionID: '',
    cookieSettings: {
      auth: true,
      base: false,
      ext: true,
    },
    firstName: null,
    lastName: null,
  });
  const [conversations, setConversations] = useState([]);
  const [customerId, setCustomerId] = useState(null);

  const sessionID = uuid();

  const [lng, setLng] = useState(null);
  const host_url = window.location.host;

  const handleLanguageChange = (code) => {
    i18n.changeLanguage(code);
    setLng(code);
  };

  if (userCookieSettings.trck) {
    initGA();
  }

  useEffect(() => {
    // Get User Coordinates
    getCoordinates()
      .then((location) => {
        setUser({ location, sessionID, cookieSettings });
      })
      .catch((err) => {
        console.log(err.message);
        let location = { longitude: '', latitude: '' };
        setUser({ location, sessionID, cookieSettings });
      });
  }, []);

  useEffect(() => {
    let intervalId;
    if (auth && auth.auth_info) {
      setConversations([]);
      clearInterval(intervalId);
      return;
    }

    getConversationsForCustomer();
    setCustomerId(localStorage.getItem('intu_customer_id'));

    // Set up polling for every 10 sec
    intervalId = setInterval(getConversationsForCustomer, 10_000);
    return () => clearInterval(intervalId);
  }, [auth]);

  const axiosPrivate = useAxiosPrivate();

  const getConversations = async () => {
    try {
      const { data, status } = await axiosPrivate.get(`/api/chat`);
      if (status === 200) {
        const conversations = data.data.map((conversation) => {
          const mostRecentMessage = conversation.messages.reduce(
            (latest, current) => {
              // Check if the current message is NOT from the specified user
              // Also check if the user is a company, check if not that company
              if (
                current.sender_id !== auth.user_info._id &&
                auth.user_info.account_type === 'manufacturer' &&
                current.sender_id !== auth.user_info.company
              ) {
                // If we haven't found a valid message yet, or if this message is more recent
                if (
                  !latest ||
                  new Date(current.created_at) > new Date(latest.created_at)
                ) {
                  return current;
                }
              }
              return latest;
            },
            null,
          );
          conversation.most_recent_message = mostRecentMessage;
          return conversation;
        });
        setConversations(conversations);
      }
      if (status === 240) {
        setConversations([]);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  // TO DO: -> Remove if statement after fixing the CHAT
  useEffect(() => {
    if (auth && auth.user_info && auth.user_info.account_complete) {
      if (process.env.REACT_APP_ENV === 'development') {
        getConversations();
      }
    }
  }, [auth]);

  const sendMessage = async (message, chat_id) => {
    if (!auth || !auth.user_info._id) {
      await sendMessageToSalesEngineer(message, chat_id);
      return;
    }

    const payload = {
      message,
      chat_id,
    };

    try {
      const { data, status } = await axiosPrivate.post(
        `/api/chat/message`,
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (status === 200) {
        setConversations((prevState) => {
          return prevState.map((conversation) => {
            if (conversation.chat_id === chat_id) {
              return {
                ...conversation,
                messages: data.data,
              };
            }
            return conversation;
          });
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const getConversationsForCustomer = async () => {
    const intu_customer_id =
      localStorage.getItem('intu_customer_id') || customerId;

    if (!auth.auth_info && intu_customer_id) {
      const { data, status } = await axios.get(
        `/api/chat/customer/${intu_customer_id}`,
      );

      if (status === 200) {
        setConversations((prevState) => {
          const currentMessageSum = prevState.reduce((prev, curr) => {
            return (prev += curr.messages.length);
          }, 0);

          const newMessageSum = data.data.reduce((prev, curr) => {
            return (prev += curr.messages.length);
          }, 0);

          // If we don't have new messages don't set new state and prevent re-render
          if (currentMessageSum === newMessageSum) return prevState;

          return data.data;
        });
      }
    }
  };

  const sendMessageToSalesEngineer = async (message, chat_id) => {
    const payload = {
      message,
      chat_id,
      customer_id: customerId,
    };

    try {
      const { data, status } = await axiosPrivate.post(
        `/api/chat/message-sales-engineer`,
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      if (status === 200) {
        setConversations([...data.data]);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const markMessagesRead = async (chat_id) => {
    const payload = {
      chat_id,
    };

    try {
      const { status } = await axiosPrivate.post(
        `/api/chat/mark-read`,
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (status === 200) {
        setConversations((prevState) => {
          return prevState.map((conversation) => {
            if (conversation.chat_id === chat_id) {
              return {
                ...conversation,
                unread_count: 0,
                messages: conversation.messages.map((message) => {
                  if (message.sender_id === auth.user_info._id) {
                    message.unread = false;
                  }
                  return message;
                }),
              };
            }
            return conversation;
          });
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const markMessagesReadForCustomer = async (chat_id, customer_id) => {
    const payload = {
      chat_id,
      customer_id,
    };

    try {
      const { status } = await axiosPrivate.post(
        `/api/chat/mark-read-for-customer`,
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (status === 200) {
        setConversations((prevState) => {
          return prevState.map((conversation) => {
            if (conversation.chat_id === chat_id) {
              return {
                ...conversation,
                unread_count: 0,
                messages: conversation.messages.map((message) => {
                  if (message.sender_id === auth.user_info._id) {
                    message.unread = false;
                  }
                  return message;
                }),
              };
            }
            return conversation;
          });
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const getChatDocument = async (document_id, chat_id) => {
    try {
      const { data, status } = await axiosPrivate.get(
        `/api/chat/chat-document/${document_id}`,
      );

      if (status === 200) {
        setConversations((prevState) => {
          return prevState.map((conversation) => {
            if (conversation.chat_id === chat_id) {
              return {
                ...conversation,
                chat_document: data.data,
              };
            }
            return conversation;
          });
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const counterOffer = async (chat_id, newOffer) => {
    let payload = {
      chat_id,
      ...newOffer,
    };

    const accepted = {
      accepted: true,
      ip: user.location.ip,
      date: new Date(),
      unixTimeStamp: Math.floor(Date.now() / 1000),
      agent: navigator.userAgent,
    };

    // Add accepted data
    if (auth.user_info.account_type === 'manufacturer') {
      payload = {
        ...payload,
        manufacturer_accepted: accepted,
      };
    } else if (auth.user_info.account_type === 'salesrep') {
      payload = {
        ...payload,
        associate_accepted: accepted,
      };
    }

    try {
      const { data, status } = await axiosPrivate.put(
        `/api/chat/counter-offer`,
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (status === 200) {
        setConversations((prevState) => {
          return prevState.map((conversation) => {
            if (conversation.chat_id === chat_id) {
              return {
                ...conversation,
                messages: data.data[0].messages,
              };
            } else {
              return conversation;
            }
          });
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const acceptOffer = async (chat_id, doc_id, other_user_id) => {
    let payload = {
      chat_id,
      doc_id,
      other_user_id,
    };

    const accepted = {
      accepted: true,
      ip: user.location.ip,
      date: new Date(),
      unixTimeStamp: Math.floor(Date.now() / 1000),
      agent: navigator.userAgent,
    };

    // Add accepted data
    if (auth.user_info.account_type === 'manufacturer') {
      payload = {
        ...payload,
        manufacturer_accepted: accepted,
      };
    } else if (auth.user_info.account_type === 'salesrep') {
      payload = {
        ...payload,
        associate_accepted: accepted,
      };
    }

    try {
      const { data, status } = await axiosPrivate.put(
        `/api/chat/accept-offer`,
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (status === 200) {
        setConversations((prevState) => {
          return prevState.map((conversation) => {
            if (conversation.chat_id === chat_id) {
              return {
                ...conversation,
                messages: data.data[0].messages,
              };
            } else {
              return conversation;
            }
          });
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const declineOffer = async (chat_id, doc_id, other_user_id) => {
    const payload = {
      chat_id,
      doc_id,
      other_user_id,
    };

    try {
      const { data, status } = await axiosPrivate.put(
        `/api/chat/decline-offer`,
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (status === 200) {
        setConversations((prevState) => {
          return prevState.map((conversation) => {
            if (conversation.chat_id === chat_id) {
              return {
                ...conversation,
                messages: data.data[0].messages,
              };
            } else {
              return conversation;
            }
          });
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const [selectedChatId, setSelectedChatId] = useState(null);
  const [activeChatIds, setActiveChatIds] = useState(new Set());
  const [isChatDialogOpen, setIsChatDialogOpen] = useState(false);

  return (
    <UserContext.Provider
      value={{
        host_url,
        user,
        setUser,
        cookieSettings,
        setCookieSettings,
        languages,
        setLanguages,
        lng,
        setLng,
        handleLanguageChange,
        conversations,
        setConversations,
        customerId,
        sendMessage,
        markMessagesRead,
        markMessagesReadForCustomer,
        getConversations,
        getChatDocument,
        counterOffer,
        acceptOffer,
        declineOffer,
        sendMessageToSalesEngineer,
        selectedChatId,
        setSelectedChatId,
        activeChatIds,
        setActiveChatIds,
        isChatDialogOpen,
        setIsChatDialogOpen,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
