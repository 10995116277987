import { createContext, useContext, useRef } from 'react';
import { TextField, Grid, Skeleton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper.jsx';
import { getNestedValue } from '../../../helpers/getNestedValuesIn.js';
import ProcessingContext from '../../../context/providers/ProcessingProvider.jsx';

// export const BusinessNameFieldContext = createContext({});

const BusinessNameTextField = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'company', // Fetermine the Name and ID of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'BusinessNameFieldManual', // KeyPrefix which holds label and placeholder
  transTypeID = 'user', // Defines The Translation Type e.g. User, Company
  transition = true, // Transition of the Field (true = fade in, false = fadeout)
  disabled = false, // Whether the field is disabled
  required = false, // Whether the field is required
  variant = 'outlined', // MUI variant
  ...props
}) => {
  // Translation
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: transTypes } = useTranslation('types');

  const { values, errors, handleBlur, setFieldValue, touched } =
    useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const businessNameRef = useRef();

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <Grid container alignItems="center" direction={{ xs: 'column', lg: 'row' }}>
      <Grid item xs={12} lg={12} sx={{ width: '100%' }}>
        <FormikFieldWrapper
          fieldTitle={fieldTitle}
          fieldDescription={fieldDescription}
          transition={transition}
        >
          <TextField
            {...props}
            disabled={disabled}
            required={required}
            autoComplete="off"
            type="text"
            label={transFields('label', { type: transTypes(transTypeID) })}
            placeholder={transFields('placeholder', {
              type: transTypes(transTypeID),
            })}
            clearicon={<ClearIcon sx={{ color: 'var(--intu-lightGrey)' }} />}
            id={fieldID}
            name={fieldID}
            className="form-select-field"
            sx={{ paddingBottom: '1rem' }}
            ref={businessNameRef}
            inputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
            onBlur={handleBlur}
            value={fieldValue}
            error={fieldError && fieldTouched ? true : false}
            helperText={fieldError && fieldTouched ? fieldError : null}
            variant={variant}
          />
        </FormikFieldWrapper>
      </Grid>
    </Grid>
  );
};

export default BusinessNameTextField;
