import { memo, useContext, useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Typography,
  Select,
  ClickAwayListener,
  Slider,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import * as THREE from 'three';
import PaletteIcon from '@mui/icons-material/Palette';
import { useTranslation } from 'react-i18next';
import { ProductMeshContext } from './context/ProductMeshContext';
import IntuIconButton from '../../components/buttons/IntuIconButton';

const createHexString = (color) => {
  const { r, g, b } = color;
  const threeJsColor = {
    r,
    g,
    b,
  };
  const hexColor =
    '#' +
    new THREE.Color(
      threeJsColor.r,
      threeJsColor.g,
      threeJsColor.b,
    ).getHexString();
  return hexColor;
};

const options = Array.from({ length: 11 }, (_, i) =>
  Number((i * 0.1).toFixed(1)),
);

const ColorControlsContainer = ({
  showingArray,
  setShowingArray,
  scene,
  viewMode = 'edit',
}) => {
  const { t } = useTranslation();
  const { meshes, setMeshes, setInitialMeshes, setIsProductImageDirtied } =
    useContext(ProductMeshContext);

  const [showingColorOptions, setShowingColorOptions] = useState(false);

  useEffect(() => {
    handleSceneLoaded();
  }, []);

  const handleSceneLoaded = () => {
    if (viewMode === 'edit') {
      let meshObjects = [];
      let initialMeshStates = [];

      scene.traverse((child) => {
        if (child.isMesh && child.name) {
          meshObjects.push(child);
          // Store initial state with explicit color, roughness, and metalness values
          initialMeshStates.push({
            name: child.name,
            color: child.material.color.getHex(),
            roughness: child.material.roughness,
            metalness: child.material.metalness,
          });
        }
      });

      setMeshes(meshObjects);
      setInitialMeshes(initialMeshStates);
      setShowingArray(Array.from({ length: meshObjects.length }, () => false));
    }
  };

  const handleColorChangeComplete = (newColor, index) => {
    const { hex } = newColor;
    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.color.set(hex);
        return m;
      });
      return newMeshes;
    });

    handleColorBoxClick(index);
    setIsProductImageDirtied(true);
  };

  const handleColorBoxClick = (index) => {
    setShowingArray((prevState) => {
      return prevState.map((x, i) => {
        if (i !== index) return false;
        return x === false ? true : false;
      });
    });
  };

  const handleRoughnessChange = (e, index) => {
    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.roughness = Number(e.target.value);
        return m;
      });
      return newMeshes;
    });
    setIsProductImageDirtied(true);
  };

  const handleMetalnessChange = (e, index) => {
    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.metalness = Number(e.target.value);
        return m;
      });
      return newMeshes;
    });
    setIsProductImageDirtied(true);
  };

  const handleShowColorOptionsClick = () => {
    setShowingColorOptions(!showingColorOptions);
  };

  const handleClickAway = () => {
    // Close color picker if open
    if (showingArray.some((showing) => showing)) {
      setShowingArray(Array(showingArray.length).fill(false));
    }
    // Close the entire color controls panel
    setShowingColorOptions(false);
  };

  if (!meshes.length) return null;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="absolute" top={150} left={20}>
        <IntuIconButton
          type="add"
          variant="outlined"
          tooltipTitle={`${showingColorOptions ? 'Hide' : 'Show'} Color Options`}
          IconComponent={PaletteIcon}
          onClick={handleShowColorOptionsClick}
        />
        <Box
          position="absolute"
          top={30}
          left={20}
          minHeight={450}
          maxHeight="100%"
          minWidth={320}
          overflow="scroll"
          mt={2}
          display={showingColorOptions ? 'block' : 'none'}
          p={2}
          sx={{ backgroundColor: (theme) => theme.palette.secondary.dark }}
          zIndex={1}
          borderRadius={1}
        >
          <Box display="flex" gap={2}>
            <Typography minWidth={150}>
              {t('components.colorControls.mesh')}
            </Typography>
            <Typography>{t('components.colorControls.color')}</Typography>
            <Typography>{t('components.colorControls.rough')}</Typography>
            <Typography>{t('components.colorControls.metal')}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={1} mt={2}>
            {meshes.map((m, i) => (
              <Box
                key={m.uuid}
                display="flex"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography flex={0.8} minWidth={150}>
                  {m.name}
                </Typography>
                <Box minWidth={35}>
                  {showingArray[i] ? (
                    <Box position="absolute" zIndex={100} left={100}>
                      <SketchPicker
                        color={createHexString(m.material.color)}
                        onChangeComplete={(newColor) =>
                          handleColorChangeComplete(newColor, i)
                        }
                      />
                    </Box>
                  ) : (
                    <Box
                      style={{
                        backgroundColor: createHexString(m.material.color),
                        width: 35,
                        height: 35,
                        border: '1px solid #000',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleColorBoxClick(i)}
                    />
                  )}
                </Box>
                <Box minWidth={100}>
                  <Slider
                    type="range"
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e) => handleRoughnessChange(e, i)}
                  />
                </Box>
                <Box minWidth={100}>
                  <Slider
                    type="range"
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e) => handleMetalnessChange(e, i)}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default memo(ColorControlsContainer);
