import { memo } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import USAMap from '../maps/USAMap';

const Find = ({
  filters,
  list,
  filteredList,
  columns,
  onFilterChange,
  filtersHaveValues = false,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  // Extract selected regions from the map filter
  const selectedRegions =
    filters.find((filter) => filter.id === 'map')?.value || [];
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="repeat(3, auto)"
        gap={2}
        bgcolor="background.default"
        p={4}
        borderRadius="12px"
        boxShadow={6}
      >
        {filters.map((filter) => {
          if (filter.type === 'autocomplete') {
            return (
              <Autocomplete
                key={filter.id}
                multiple
                value={filter.value}
                label={filter.label}
                options={filter.options}
                onChange={(e, newValue, reason, details) =>
                  onFilterChange(newValue, reason, details, filter.id)
                }
                renderInput={(params) => (
                  <TextField {...params} label={filter.label} />
                )}
              />
            );
          } else if (filter.type === 'map') {
            return (
              <Box key={filter.id} gridRow="1 / -1">
                <USAMap
                  onRegionSelect={(region) => onFilterChange(region, 'map')}
                  availableRegions={
                    filtersHaveValues
                      ? filteredList.map((item) => item.address)
                      : list.map((item) => item.address)
                  }
                  selectedRegions={selectedRegions}
                />
              </Box>
            );
          } else {
            return null;
          }
        })}
      </Box>
      <Box mt={4}>
        <DataGrid
          disableRowSelectionOnClick
          rows={filteredList}
          columns={columns}
          columnVisibilityModel={{
            applications: !isMobileView,
            product_types: !isMobileView,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
            sorting: {
              sortModel: [{}],
            },
          }}
          pageSizeOptions={[5, 10, 100]}
          checkboxSelection={false}
          disableColumnMenu
          slots={{
            Row: MemoizedRow,
            ColumnHeaders: MemoizedColumnHeaders,
          }}
          getRowClassName={(params) =>
            params.row.relationship_status === 'pending' ? 'pending-row' : ''
          }
          sx={{
            borderRadius: '12px',
            boxShadow: 6,
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .pending-row': {
              backgroundColor: theme.palette.edit.dark,
            },
          }}
        />
      </Box>
    </>
  );
};

export default Find;
