import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import parse from 'html-react-parser';
import { Typography, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntuTheme } from '../../../../context/providers/IntuThemeProvider';
import { LeadContext } from '../../../../context/providers/leads/LeadContextProvider.jsx';
import { Video } from '../../../../plugins/BackgroundVideoPlayer';

const HowToSignUpManufacturers = () => {
  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks.lead_context',
  });

  // Theme
  const { mode } = useIntuTheme();

  //   Lead context
  const { leadContext } = useContext(LeadContext);

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      className="intu__divider"
      sx={{ padding: '50px 20px 0 20px' }}
    >
      <Grid item xs={12} sx={{ marginBottom: '50px' }}>
        <Typography variant="h1" textAlign="center">
          {leadContext?.how_it_works?.title}
        </Typography>
        {leadContext?.how_it_works?.description && (
          <Typography
            variant="body1"
            className={
              mode === 'light'
                ? 'grey-text content-centered'
                : 'white-text content-centered'
            }
          >
            {leadContext?.how_it_works?.description}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems="flex-start"
        justifyContent={{ md: 'center' }}
      >
        {/* STEPS */}
        <Grid
          item
          xs={12}
          md={5}
          container
          alignItems="center"
          direction="column"
        >
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {leadContext?.how_it_works?.steps?.map((step, index) => {
              return (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      {step.icon}
                    </TimelineDot>
                    {step.seperator ? (
                      <TimelineConnector
                        sx={{ bgcolor: 'secondary.primary', height: '4rem' }}
                      />
                    ) : null}
                  </TimelineSeparator>
                  <TimelineContent color="secondary">
                    <Typography variant="h5" color="var(--intu-darkGreen)">
                      {parse(step.header)}
                    </Typography>
                    <Typography variant="body1">
                      {parse(step.content)}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Grid>

        {/* Explainer Video */}
        {leadContext?.how_it_works?.video && (
          <Grid item xs={12} md={4}>
            <Video blur={0} videoSource={leadContext?.how_it_works.video} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export { HowToSignUpManufacturers };
