import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { invertColor } from '../helpers/invertColor';

// Create a function to handle the theme creation logic
export const createCustomUserTheme = (baseTheme, userTheme) => {
  const contrastTextColor = invertColor(userTheme.primary.main);
  return responsiveFontSizes(
    createTheme({
      ...baseTheme,
      palette: {
        ...baseTheme.palette,
        primary: {
          ...userTheme.primary,
        },
        secondary: {
          ...userTheme.secondary,
        },
        tertiary: {
          ...baseTheme.palette.tertiary,
          main: userTheme.tertiary.main || baseTheme.palette.tertiary.main,
        },
      },
      components: {
        ...baseTheme.components,
        MuiAppBar: {
          styleOverrides: {
            ...baseTheme.components.MuiAppBar.styleOverrides,
            root: {
              ...baseTheme.components.MuiAppBar.styleOverrides.root,
              backgroundColor: userTheme.primary.main
                ? userTheme.primary.main
                : baseTheme.components.MuiAppBar.styleOverrides.root
                    .backgroundColor,
              boxShadow: userTheme.primary.main
                ? `0px 1px 12px ${userTheme.primary.main}`
                : baseTheme.components.MuiAppBar.styleOverrides.root.boxShadow,
            },
          },
          menuIcon: userTheme.primary.main
            ? userTheme.primary.main
            : baseTheme.components.MuiAppBar.menuIcon,
        },
        MuiChip: {
          styleOverrides: {
            root: {
              color: contrastTextColor,
              backgroundColor: userTheme.primary.main,
              '&.Mui-disabled': {
                opacity: 1,
                color: contrastTextColor,
                backgroundColor: userTheme.primary.main,
              },
            },
            deleteIcon: {
              color: contrastTextColor,
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            ...baseTheme.components.MuiDataGrid.styleOverrides.root,
            border: 'none',
            columnHeaders: {
              ...baseTheme.components.MuiDataGrid[
                '& .MuiDataGrid-columnHeader'
              ],
              backgroundColor: userTheme.primary.main,
              color: contrastTextColor,
            },
            '& .MuiDataGrid-checkboxInput': {
              color: contrastTextColor,
            },
          },
        },
      },
    }),
  );
};
