import { useMemo, useEffect, useState } from 'react';
import { Box, Button, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import PlaceHolderImage from '../../assets/core/intuPlaceHolder.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ReactComponent as Image3DSVG } from '../../assets/new-product/3d-image.svg';
import CanvasContainer from './CanvasContainer';
import UploadImageDialog from './UploadImageDialog';
import TuneIcon from '@mui/icons-material/Tune';

const CarouselItem = ({ image, viewMode, showSliders }) => {
  if (image.endsWith('.stp')) return null;
  if (image.endsWith('.gltf')) {
    return (
      <CanvasContainer
        image={image}
        viewMode={viewMode}
        showSliders={showSliders}
      />
    );
  }
  return (
    <Paper
      elevation={0}
      square
      sx={{
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        height: '40vh',
      }}
    >
      <Box
        width="80%"
        height="80%"
        mt={6}
        sx={{ objectFit: 'contain' }}
        component="img"
        src={image}
      />
    </Paper>
  );
};

const ProductImageHeader = ({
  productImages,
  handleNewImage,
  handleEditImage,
  handleDeleteImage,
  handleSetDefaultImage,
  isUploadImageDialogOpen,
  setIsUploadImageDialogOpen,
  productId,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isShort = useMediaQuery('(max-height:950px)');

  const [activeIndex, setActiveIndex] = useState(0);
  const [prevLength, setPrevLength] = useState(0);
  const [editingImage, setEditingImage] = useState(null);

  const filteredProductImages = useMemo(() => {
    if (!productImages.length) return null;
    return productImages.filter(
      (image) => !image.endsWith('.stp') && !image.endsWith('.STEP'),
    );
  }, [productImages]);

  useEffect(() => {
    if (!filteredProductImages) return;
    if (prevLength === filteredProductImages.length) {
      setActiveIndex(0);
    } else {
      setActiveIndex(filteredProductImages.length - 1);
    }
    setPrevLength(filteredProductImages.length);
  }, [filteredProductImages]);

  const images = useMemo(() => {
    if (!filteredProductImages) return [PlaceHolderImage];
    return filteredProductImages;
  }, [filteredProductImages]);

  const imageIndicators = useMemo(() => {
    if (!filteredProductImages) return null;
    return filteredProductImages.map((image) => {
      if (image.endsWith('.gltf')) {
        return <Image3DSVG width="50px" height="50px" />;
      }
      return (
        <img
          key={image}
          alt={image}
          src={image}
          style={{ width: '50px', height: '50px', margin: '0 4px' }}
        />
      );
    });
  }, [filteredProductImages]);

  const handleEditClick = () => {
    setIsUploadImageDialogOpen(true);
    setEditingImage(productImages[activeIndex]);
  };

  const handleClose = () => {
    setIsUploadImageDialogOpen(false);
    setEditingImage(null);
  };

  const handleConfirmEdit = (remoteUrl, editingImage) => {
    handleEditImage(remoteUrl, editingImage);
    setEditingImage(null);
    setIsUploadImageDialogOpen(false);
  };

  const handleDelete = (file) => {
    handleDeleteImage(file);
    setEditingImage(null);
    setIsUploadImageDialogOpen(false);
  };

  const handleSetDefault = (file) => {
    handleSetDefaultImage(file);
    setEditingImage(null);
    setIsUploadImageDialogOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      position="relative"
    >
      <Box
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
        mr={4}
        mb={2}
      >
        <Button
          component="span"
          variant="outlined"
          startIcon={<FileUploadIcon sx={{ color: 'tertiary.main' }} />}
          disabled={productImages.length > 10}
          sx={{
            textTransform: 'none',
            borderRadius: '24px',
            minWidth: 187,
            height: 40,
          }}
          color="info"
          onClick={() => setIsUploadImageDialogOpen(true)}
        >
          {t('buttons.upload_img')}
        </Button>
        {productImages[activeIndex] ? (
          <Button
            component="span"
            variant="outlined"
            startIcon={<TuneIcon />}
            disabled={productImages.length > 10}
            sx={{
              textTransform: 'none',
              mt: 2,
              borderRadius: '24px',
              minWidth: 187,
              height: 40,
            }}
            color="secondary"
            onClick={handleEditClick}
          >
            {t('buttons.edit_img')}
          </Button>
        ) : null}
      </Box>
      <Carousel
        style={{ flex: 1 }}
        index={activeIndex}
        animation="slide"
        navButtonsAlwaysVisible={images.length > 1}
        autoPlay={false}
        swipe={false}
        IndicatorIcon={imageIndicators}
        indicatorContainerProps={{
          style: {
            background: productImages.length > 0 ? 'white' : 'initial',
            margin: isShort ? '180px auto 0' : '0 auto',
            width: '40%',
            borderRadius: '5px',
            padding: '10px 0',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            background: theme.palette.primary.main,
          },
        }}
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosNewIcon />}
        navButtonsProps={{
          style: {
            // backgroundColor: 'transparent',
            // color: 'black',
            display: productImages.length > 1 ? 'inline-flex' : 'none',
          },
        }}
        width={{ xs: '60vw', md: '100vw' }}
        height="40vh"
        onChange={(now) => setActiveIndex(now)}
      >
        {images.map((image, i) => {
          if (editingImage) return null;
          return <CarouselItem key={i} image={image} showSliders={false} />;
        })}
      </Carousel>
      <UploadImageDialog
        isOpen={isUploadImageDialogOpen}
        handleClose={handleClose}
        handleConfirm={handleNewImage}
        handleConfirmEdit={handleConfirmEdit}
        handleDeleteImage={handleDelete}
        productId={productId}
        editingImage={editingImage}
        handleSetDefaultImage={handleSetDefault}
      />
    </Box>
  );
};

export default ProductImageHeader;
