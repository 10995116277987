import { useContext } from 'react';
import {
  Autocomplete,
  Skeleton,
  TextField,
  createFilterOptions,
  Grid,
  Box,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler';
import { useTranslation } from 'react-i18next';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';
import AddIcon from '@mui/icons-material/Add';
import { CompanyPaymentTermsDialog } from './CompanyPaymentTermsDialog';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider';

const CompanyPaymentTermsFieldContent = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'payment_terms', // Define the Fields Name and ID
  autocompleteID = 'payment_terms', // Define the Fields Name and ID
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'PaymentTermsField', // KeyPrefix which holds label and placeholder
  transition = true, // Transition true = fade in, false = fade out
  disabled = false, // Disable the Field
  required = false, // Field Required
  variant = 'outlined', // Set the Variant type standard or outlined
}) => {
  const { values, handleBlur, setFieldValue, touched, errors } =
    useFormikContext();

  const { isLoading } = useProcessingHandler();

  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const filter = createFilterOptions();

  const {
    paymentTermOptions,
    setNewPaymentTermDialogOpen,
    setNewPaymentTerm,
    newPaymentTermsList,
    setNewPaymentTermsList,
  } = useContext(CompanyProfileContext);

  const handlePaymentTermChange = (e, options, reason, element) => {
    console.log('Reason -> ', reason);
    if (reason === 'createOption') {
      const val = options.pop();
      const newTerm = { payment_term: `${val} Days Net` };
      options.push(newTerm);
      setNewPaymentTermsList([...newPaymentTermsList, newTerm]);
      setFieldValue('payment_terms', options);
    } else if (
      reason === 'selectOption' &&
      element.option.payment_term?.search('Add ') >= 0
    ) {
      options.pop();
      setNewPaymentTermDialogOpen(true);
      setNewPaymentTerm({ payment_term: 0 });
    } else if (reason === 'removeOption') {
      const elementToRemove = element.option;
      const filtered = newPaymentTermsList.filter(
        (item) => item.payment_term !== elementToRemove.payment_term,
      );
      setNewPaymentTermsList(filtered);
      setFieldValue('payment_terms', options);
    } else {
      setFieldValue('payment_terms', options);
    }
  };

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <>
      <FormikFieldWrapper
        fieldTitle={fieldTitle}
        fieldDescription={fieldDescription}
        transition={transition}
      >
        <Autocomplete
          multiple
          freeSolo
          value={getNestedValue(values, autocompleteID)}
          required={required}
          disabled={disabled || isLoading?.status ? true : false}
          id={fieldID}
          name={fieldID}
          options={paymentTermOptions}
          onBlur={handleBlur}
          sx={{ paddingBottom: '50px' }}
          className="form-select-field"
          getOptionLabel={(option) => option.payment_term}
          onChange={handlePaymentTermChange}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            filtered.push({
              payment_term: `${t('add_custom')}`,
            });
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              variant={variant}
              label={!disabled ? t('label') : null}
              placeholder={!disabled ? t('placeholder') : null}
              error={errors[fieldID] && touched[fieldID] ? true : false}
              helperText={
                errors[fieldID] && touched[fieldID] ? errors[fieldID] : null
              }
            />
          )}
          renderOption={(props, option, element) => {
            const existingTerm = paymentTermOptions.some(
              (item) => item.payment_term === option.payment_term,
            );
            return !existingTerm ? (
              <li {...props} key={props.key}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <AddIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item>
                    <Box key={option.payment_term} component="span">
                      {option.payment_term}
                    </Box>
                  </Grid>
                </Grid>
              </li>
            ) : (
              <li {...props} key={props.key}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box key={option.payment_term} component="span">
                      {option?.payment_term}
                    </Box>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </FormikFieldWrapper>
      <CompanyPaymentTermsDialog />
    </>
  );
};

const CompanyPaymentTermsField = (props) => {
  return <CompanyPaymentTermsFieldContent {...props} />;
};

export default CompanyPaymentTermsField;
