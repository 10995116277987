import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttributeTypeBox from './AttributeTypeBox';
import AttributeBox from './AttributeBox';
import AttributeValueBox from './AttributeValueBox';
import axios from '../../hooks/axios/axios';
import { axiosPrivate } from '../../hooks/axios/axios';

const AddAttributeDialog = ({
  product,
  setProduct,
  productAttributeOptions,
  setProductAttributeOptions,
  selectedAttributeType,
  setSelectedAttributeType,
  selectedAttribute,
  setSelectedAttribute,
  selectedAttributeValue,
  setSelectedAttributeValue,
  editingAttribute,
  setEditingAttribute,
  isOpen,
  handleConfirm,
  handleClose,
}) => {
  const { t: transButtons } = useTranslation('buttons');
  const [selectedAttributes, setSelectedAttributes] = useState(
    new Set(product.product_attributes),
  );

  useEffect(() => {
    setSelectedAttributes(new Set(product.product_attributes));
  }, [product.product_attributes]);

  useEffect(() => {
    getAttributesOptions();
  }, []);

  const getAttributesOptions = async () => {
    try {
      const { data, status } = await axios.get('/api/product-attributes');
      if (status === 200) {
        setProductAttributeOptions(data.data);
      }
    } catch (error) {
      console.error(error, 'error');
    }
  };

  const handleAttributeTypeSelect = (newAttributeType) => {
    setSelectedAttributeType(newAttributeType);
    setSelectedAttribute(null);
  };

  const handleAttributeSelect = (newAttribute, isNew = false) => {
    setSelectedAttribute(newAttribute);
    setSelectedAttributeValue(null);
    if (isNew)
      setProductAttributeOptions((prevState) => {
        const newAttributeOption = {
          attribute_type: selectedAttributeType,
          attribute: newAttribute,
          value: null,
        };
        return [...prevState, newAttributeOption];
      });
  };

  const handleAttributeValueSelect = (newAttributeValue) => {
    setSelectedAttributeValue(newAttributeValue);
    handleAttributeSave(newAttributeValue);
    handleClose();
  };

  const handleAttributeSave = async (newAttributeValue) => {
    let newAttribute = productAttributeOptions.find(
      (option) =>
        option.attribute_type === selectedAttributeType &&
        option.attribute === selectedAttribute &&
        option.value === newAttributeValue,
    );

    if (!newAttribute) {
      newAttribute = {
        attribute_type: selectedAttributeType,
        attribute: selectedAttribute,
        value: newAttributeValue,
      };
      setProductAttributeOptions((prevState) => [...prevState, newAttribute]);
      try {
        const payload = {
          ...newAttribute,
        };
        const { data, status } = await axiosPrivate.post(
          '/api/product-attributes',
          payload,
        );
        // console.log('back from adding', data, status);
      } catch (error) {
        console.error('error', error);
      }
    }

    if (editingAttribute) {
      setSelectedAttributes((prevState) => {
        const newSet = new Set(prevState);
        prevState.delete(editingAttribute);
        prevState.add(newAttribute);
        return newSet;
      });
      setEditingAttribute(null);
    } else {
      setSelectedAttributes((prevState) => {
        const newSet = new Set(prevState);
        newSet.add(newAttribute);
        return newSet;
      });
    }

    setSelectedAttributeValue(null);
    setSelectedAttribute(null);
    setProduct((prevState) => {
      return {
        ...prevState,
        product_attributes: [...prevState.product_attributes, newAttribute],
      };
    });
  };

  const handleNewAttributeConfirm = (
    selectedAttributeType,
    selectedAttribute,
    newAttributeValue,
  ) => {
    const newAttribute = {
      attribute_type: selectedAttributeType,
      attribute: selectedAttribute,
      value: newAttributeValue,
    };

    setSelectedAttributes((prevState) => prevState.add(newAttribute));

    setProductAttributeOptions((prevState) => [...prevState, newAttribute]);
    // TODO: save onto product
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>New Attribute</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <Accordion
          expanded
          sx={{
            backgroundColor: 'background.default',
            borderRadius: '5px',
            boxShadow: 6,
          }}
        >
          <AccordionSummary
            sx={{
              '.MuiAccordionSummary-content': {
                margin: 0,
              },
              '&.MuiAccordionSummary-root.Mui-expanded': {
                minHeight: '48px',
              },
            }}
          >
            <Typography
              textTransform="none"
              p={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex={1}
              color="primary"
              fontSize="1.2rem"
            >
              Add a Product Attribute
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              alignItems="stretch"
              justifyContent="space-evenly"
              gap={2}
            >
              <AttributeTypeBox
                options={productAttributeOptions}
                selectedAttributeType={selectedAttributeType}
                handleAttributeTypeSelect={handleAttributeTypeSelect}
              />
              <AttributeBox
                options={productAttributeOptions}
                selectedAttributeType={selectedAttributeType}
                handleAttributeSelect={handleAttributeSelect}
                selectedAttribute={selectedAttribute}
                selectedAttributes={selectedAttributes}
              />
              <AttributeValueBox
                options={productAttributeOptions}
                selectedAttributeType={selectedAttributeType}
                selectedAttribute={selectedAttribute}
                handleAttributeValueSelect={handleAttributeValueSelect}
                selectedAttributeValue={selectedAttributeValue}
                handleNewAttributeConfirm={handleNewAttributeConfirm}
              />
              <Box alignSelf="center"></Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button onClick={handleClose} color="secondary" variant="contained">
          {transButtons('close')}
        </Button>
        <Button
          onClick={() => {}}
          color="primary"
          type="submit"
          variant="contained"
        >
          {transButtons('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAttributeDialog;
