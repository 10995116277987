import { createContext, useContext, useState } from 'react';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import { searchBrands } from '../../../routes/brandRoutes';
import { searchApplications } from '../../../routes/applicationsRoutes';
import {
  searchProductTypes,
  searchProducts,
} from '../../../routes/productsRoutes';
import UserContext from '../UserInfoProvider';
import { useTranslation } from 'react-i18next';
import { searchOrders } from '../../../routes/orderRoutes';
import { searchOffers } from '../../../routes/offerRoutes';
import { searchCustomers } from '../../../routes/customerRoutes';
import { searchOpportunities } from '../../../routes/opportunityRoutes';
import { searchOrderItems } from '../../../routes/orderItemRoutes';
import useUser from '../../../hooks/useUser';
import { searchRelationships } from '../../../routes/relationshipsRoutes';
import { CompanyProfileContext } from '../CompanyProfileProvider';

export const PublicProfileContext = createContext();

export const PublicProfileProvider = ({ children }) => {
  const { getConversations } = useContext(UserContext);
  const { companyProfile } = useContext(CompanyProfileContext);
  const axios = useAxiosPrivate();
  const { t: transTypes } = useTranslation('types');
  const { handleErrorResponse } = useResponseHandling();
  const { user } = useUser();

  const [orders, setOrders] = useState([]);
  const [offers, setOffers] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [orderChartData, setOrderChartData] = useState([]);
  const [offerChartData, setOfferChartData] = useState([]);
  const [opportunityChartData, setOpportunityChartData] = useState([]);
  const [activeChartData, setActiveChartData] = useState('order');
  const [chartMaxValues, setChartMaxValues] = useState({});
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);

  async function handleGetBrands(brandsById) {
    // Guard Clause
    if (brandsById?.length === 0) {
      return;
    }

    let companyBrands = [];
    try {
      const payload = {
        key: '_id',
        values: brandsById,
      };
      const { data, status } = await searchBrands(payload);
      if (status === 'success') {
        companyBrands = data;
      }
    } catch (err) {
      handleErrorResponse(err);
      console.error('error', err);
    }

    return companyBrands;
  }

  // Search Products
  async function handleGetProducts(companyEmail) {
    let companyProducts = [];
    try {
      const payload = {
        'author.email': companyEmail,
      };
      const { data, status } = await searchProducts(payload);

      if (status === 'success') {
        companyProducts = data;
      }
    } catch (err) {
      handleErrorResponse(err);
      console.error('error', err);
    }

    return companyProducts;
  }

  // Search Applications
  async function handleGetApplications(applicationsById) {
    // Guard Clause
    if (applicationsById?.length === 0) {
      return;
    }

    let companyApplications = [];
    try {
      const payload = {
        key: '_id',
        values: applicationsById,
      };
      const { status, data } = await searchApplications(payload);
      if (status === 'success') {
        companyApplications = data;
      } else {
        return [];
      }
    } catch (err) {
      handleErrorResponse(err);
      console.error('error', err);
    }

    return companyApplications;
  }

  // Search Product Types
  async function handleGetProductTypes(productTypeId) {
    // Guard Clause
    if (productTypeId?.length === 0) {
      return;
    }
    let productTypes;
    try {
      const payload = {
        key: '_id',
        values: productTypeId,
      };
      const { status, data } = await searchProductTypes(payload);
      if (status === 'success') {
        productTypes = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return productTypes;
  }

  async function handleSearchForAssociatedCustomers(id) {
    if (!id) {
      return;
    }

    const payload = {
      key: 'associated_to',
      values: [id],
    };

    try {
      const { data, statusCode, message, status } =
        await searchCustomers(payload);

      if (statusCode === 200) {
        return data;
      } else {
        return {
          message,
          status,
          statusCode,
        };
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  }

  async function requestToRecruit(newApplication, id, name) {
    const url = `/api/chat/request-to-recruit`;

    const manufacturer_accepted = {
      accepted: true,
      ip: user.location.ip,
      date: new Date(),
      unixTimeStamp: Math.floor(Date.now() / 1000),
      agent: navigator.userAgent,
    };

    const payload = {
      ...newApplication,
      id,
      chat_title: `Recruit Request ${name} - ${companyProfile.name}`,
      manufacturer_accepted,
    };

    try {
      const { status } = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      if (status === 200) {
        getConversations();
      }
    } catch (error) {
      console.error('error', error);
    }
  }

  // Grab orders associated to user
  async function handleSearchOrders(id, key) {
    const req_fields = [];
    if (!id) {
      req_fields.push();
    }

    const payload = {
      key: key,
      value: [id],
    };

    try {
      const { data, status, message } = await searchOrders(payload);

      if (status === 'success') {
        setOrders(data);
      } else {
        setOrders([]);
      }
    } catch (error) {
      console.error(error);
      handleErrorResponse(error);
    }
  }

  // Grab offers associated to user
  async function handleSearchOffers(id, key) {
    const payload = {
      key: key,
      value: [id],
    };
    try {
      const { data, status, message } = await searchOffers(payload);

      if (status === 'success') {
        setOffers(data);
      } else {
        setOffers([]);
      }
    } catch (error) {
      console.error(error);
      handleErrorResponse(error);
    }
  }

  // Format Order and Offers data by month and year
  function formatDataByMonth(arr, type) {
    const newArr = [];
    arr?.forEach((element) => {
      const date =
        type === 'order'
          ? new Date(element.order_date)
          : type === 'offer'
            ? new Date(element.offer_date)
            : new Date(element.close_date);
      const month = date.getMonth();
      const year = date.getFullYear();
      const month_name = date.toLocaleString('default', { month: 'long' });

      // Create a category string that includes both month and year
      const category = `${month_name} ${year}`;

      // Search if month-year key exists
      const lowercase_month = month_name.toLowerCase();
      const trans_month = transTypes(`months.${lowercase_month}`);
      const month_year_obj = newArr.find((item) => item.category === category);

      // If month-year combination doesn't exist
      if (!month_year_obj) {
        newArr.push({
          category: `${trans_month} ${year}`,
          value: element.grand_total,
          date: date, // Store the full date for sorting
        });
      } else {
        month_year_obj.value += element.grand_total;
      }
    });

    // Sort the array by date
    newArr.sort((a, b) => a.date - b.date);

    return newArr;
  }

  async function handleSearchOpportunities(id, key) {
    const req_fields = [];
    if (!id) {
      req_fields.push();
    }

    const payload = {
      key: key,
      value: [id],
    };

    try {
      const { data, status, message } = await searchOpportunities(payload);

      if (status === 'success') {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error(error);
    }
  }

  async function handleSearchOrderItems(id, key) {
    const req_fields = [];
    if (!id) {
      req_fields.push();
    }

    const payload = {
      key: key,
      value: id,
    };

    try {
      const { data, status, message } = await searchOrderItems(payload);

      if (status === 'success') {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error(error);
    }
  }

  const chartSettings = {
    chartHeight: 500,
    label: '$',
    type: 'Bar Chart',
    minValue: 0,
    maxValue: chartMaxValues[activeChartData],
    maxPrecision: 10,
    showCursor: false,
    showDataLabels: false,
    showLegend: false,
  };

  const processData = (data) => {
    let maxValue = 0;
    const processedData = data.map((month) => {
      const value = +month.value?.toFixed(2) || 0;
      maxValue = Math.max(maxValue, value);
      return { ...month, value };
    });
    return { processedData, maxValue };
  };

  // Helper function to update chart data and max values
  const updateChartData = (data, type, seriesName, setChartData) => {
    const { processedData, maxValue } = processData(data);

    setChartData([{ data: processedData, seriesName }]);
    setChartMaxValues((prev) => ({ ...prev, [type]: maxValue }));
  };

  async function getRelationships(id, type) {
    try {
      const payload = {
        key: type,
        value: id,
      };

      const { status, data } = await searchRelationships(payload);
      if (status === 'success') {
        return data.filter((relationship) => relationship.status === 'active');
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <PublicProfileContext.Provider
      value={{
        handleGetBrands,
        handleGetProducts,
        handleGetProductTypes,
        handleGetApplications,
        requestToRecruit,
        handleSearchOffers,
        handleSearchOrders,
        formatDataByMonth,
        orders,
        setOrders,
        offers,
        setOffers,
        handleSearchOpportunities,
        handleSearchOrderItems,
        isChartLoading,
        setIsChartLoading,
        isLoadingMap,
        setIsLoadingMap,
        orderChartData,
        setOrderChartData,
        offerChartData,
        setOfferChartData,
        opportunityChartData,
        setOpportunityChartData,
        chartSettings,
        activeChartData,
        setActiveChartData,
        chartMaxValues,
        setChartMaxValues,
        updateChartData,
        isLoadingOrders,
        setIsLoadingOrders,
        getRelationships,
      }}
    >
      {children}
    </PublicProfileContext.Provider>
  );
};
