import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import { useTranslation } from 'react-i18next';
import useIntuTheme from '../../../hooks/useIntuTheme.js';

const BenefitsManufacturers = () => {
  const { leadInfo } = useContext(LeadContext);
  const { mobileView } = useIntuTheme();

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks.lead_context.manu.intro',
  });

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'flex-start', md: 'space-around' }}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ padding: '50px 20px 0 20px' }}
      >
        <Grid item xs={12}>
          {mobileView ? (
            <Typography variant="h2" className="green-text" textAlign="center">
              {leadInfo?.headline_title ? leadInfo?.headline_title : t('title')}
            </Typography>
          ) : (
            <Typography variant="h2" className="green-text" textAlign="center">
              {leadInfo?.headline_title ? leadInfo?.headline_title : t('title')}
            </Typography>
          )}
        </Grid>

        {/* Intro Text */}
        <Grid container item xs={12} md={5}>
          <Typography variant="body1">
            {parse(leadInfo.headline_body)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export { BenefitsManufacturers };
