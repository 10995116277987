import React from 'react';
import { PostContent } from '../../components/blog/PostContent';
import { useLocation } from 'react-router-dom';
import { PostProvider } from '../../context/providers/PostContextProvider';

const Post = () => {
  // Location hook
  const location = useLocation();
  const mode = location?.state?.mode ? location?.state?.mode : 'viewPost';
  const link = location?.state?.link ? location?.state?.link : '';
  const id = location?.state?.id ? location?.state?.id : '';

  return (
    <PostProvider>
      <PostContent mode={mode} link={link} id={id} />
    </PostProvider>
  );
};

export default Post;
