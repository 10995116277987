import { Button, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContactUsForm from '../../components/contactUs/forms/ContactUsForm';
import PageHeader from '../../components/layout/PageHeader';
import IntutecLocationsMap from '../../components/maps/IntutecLocationsMap';
import './contact.css';
import IntuPage from '../../components/layout/IntuPage';
import CallIcon from '@mui/icons-material/Call';
import GroupsIcon from '@mui/icons-material/Groups';

function ContactUs() {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transComponents } = useTranslation('translation', {
    keyPrefix: 'components.contact',
  });
  const theme = useTheme();

  return (
    <>
      <PageHeader body={<IntutecLocationsMap />} />

      <IntuPage
        pageTitle={t('pages.ContactUs.title')}
        hCentered={true}
        sx={{ margin: '2rem 0' }}
      >
        <Grid container direction="column" spacing={4} sx={{ width: '100%' }}>
          {/* Schedule a Meeting */}
          <Grid item>
            <Section
              title={transComponents('schedule_meeting.title')}
              description={transComponents('schedule_meeting.description')}
              icon={<GroupsIcon sx={{ color: 'primary.main' }} />}
              actionLabel={transButtons('meet_us')}
              actionLink="https://meetings.hubspot.com/mkrostewitz/learn-more-about-in2tec"
            />
          </Grid>

          {/* Call Us */}
          <Grid item>
            <Section
              title={transComponents('call_us.title')}
              description={transComponents('call_us.description')}
              icon={<CallIcon sx={{ color: 'primary.main' }} />}
              actionLabel="+1 (888) 471 3736"
              actionLink="tel:+1-888-471-3736"
            />
          </Grid>

          {/* Drop Us a Message */}
          <Grid
            item
            container
            spacing={2}
            direction="column"
            alignItems="center"
            textAlign="center"
            sx={{ marginBottom: '2rem' }}
          >
            <Grid item>
              <Typography variant="h5">
                {transComponents('message.title')}
              </Typography>
              <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
                {transComponents('message.description')}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item xs={8}>
                <ContactUsForm />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </IntuPage>
    </>
  );
}

export default ContactUs;

// Section Component
const Section = ({ title, description, icon, actionLabel, actionLink }) => {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      textAlign="center"
      sx={{ marginBottom: '2rem' }}
    >
      <Grid item>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
          {description}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          startIcon={icon}
          sx={{
            width: '200px',
          }}
          href={actionLink}
          target="_blank"
        >
          {actionLabel}
        </Button>
      </Grid>
    </Grid>
  );
};
