import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIntuTheme from '../../../hooks/useIntuTheme';

// Define table data
const manufacturerData = [
  {
    label: 'potential.table.rows.annual_revenue_subscriptions',
    manual: '$7,188',
    semiAutomated: '$41,988',
    automated: '$131,988',
    subHeader: true,
  },
  {
    label: 'potential.table.rows.monthly_fee',
    manual: '$599',
    semiAutomated: '$3,499',
    automated: '$10,999',
    isChild: true,
  },
  {
    label: 'potential.table.rows.annual_revenue_commissions',
    manual: '$87,500',
    semiAutomated: '$156,250',
    automated: '$113,963',
    subHeader: true,
  },
  {
    label: 'potential.table.rows.annual_revenue',
    manual: '$1 Mio.',
    semiAutomated: '$2.5 Mio.',
    automated: '$4.5 Mio.',
    isChild: true,
  },
  {
    label: 'potential.table.rows.commissions_paid_total',
    manual: '$350,000',
    semiAutomated: '$625,000',
    automated: '$455,850',
    isChild: true,
  },
  {
    label: 'potential.table.rows.commissions_received_salesreps',
    manual: '$262,500',
    semiAutomated: '$468,750',
    automated: '$341,888',
    isChild: true,
  },

  {
    label: 'potential.table.rows.annual_revenue_advertising',
    manual: '$30,000',
    semiAutomated: '$75,000',
    automated: '$135,000',
    subHeader: true,
  },
  {
    label: 'potential.table.rows.annual_revenue_logistic',
    manual: '$42,000',
    semiAutomated: '$75,000',
    automated: '$54,702',
    subHeader: true,
  },
  {
    label: 'potential.table.rows.annual_revenue_per_manufacturer_total',
    manual: '$166,688',
    semiAutomated: '$348,238',
    automated: '$435,653',
    total: true,
  },
  {
    label: null,
    manual: '16.67%',
    semiAutomated: '13.93%',
    automated: '9.68%',
    isChild: true,
  },
];

// Define table headers
const manufacturerHeaders = [
  { key: 'type', label: null, color: null },
  {
    key: 'manual',
    label: 'potential.table.columns.manual',
    color: 'grey.light',
  },
  {
    key: 'semiAutomated',
    label: 'potential.table.columns.semi_automated',
    color: 'grey.main',
  },
  {
    key: 'automated',
    label: 'potential.table.columns.automated',
    color: 'green.dark',
  },
];

const RevenueModel = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks.lead_context.investor',
  });
  const { theme } = useIntuTheme();
  const { mobileView } = useIntuTheme();

  return (
    <>
      <Typography variant="h4">{t('potential.table.title')}</Typography>
      {/* Manufacturer Table */}
      {mobileView ? (
        // Render for mobile View
        manufacturerHeaders.map((col, colIndex) => {
          if (colIndex > 0) {
            return (
              <Paper
                key={colIndex}
                elevation={12}
                sx={{
                  width: '100%',
                  marginBottom: 2,
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}
              >
                {/* Card Header */}
                <Grid
                  container
                  sx={{
                    border: 'none',
                    backgroundColor: col?.color || 'none',
                    padding: 1,
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {t(col.label)}
                  </Typography>
                </Grid>

                {/* Card Content */}
                {manufacturerData.map((row, rowIndex) => (
                  <Grid
                    container
                    key={rowIndex}
                    direction="row"
                    sx={{
                      backgroundColor: col.color,
                      padding: 1,

                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {row?.label ? t(row.label) : null}
                    </Typography>
                    <Typography variant="body1">{row[col.key]}</Typography>
                  </Grid>
                ))}
              </Paper>
            );
          }
        })
      ) : (
        // Render for Desktop View
        <TableContainer
          component={Paper}
          elevation={20}
          sx={{ maxWidth: '100%', overflowX: 'auto' }}
        >
          <Table>
            {/* Table Header */}
            <TableHead>
              <TableRow>
                {manufacturerHeaders.map((header) => (
                  <TableCell
                    key={header.key}
                    align="center"
                    sx={{
                      backgroundColor: header.color || 'transparent',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      color: 'white',
                    }}
                  >
                    {header?.label ? t(header.label) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {manufacturerData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    borderBottom: row?.total
                      ? `1px solid ${theme.palette.green.main}`
                      : row?.subHeader
                        ? null
                        : null,
                    border: row?.subHeader
                      ? `1px solid ${theme.palette.grey.dark}`
                      : null,
                    backgroundColor: row?.subHeader
                      ? 'grey.main'
                      : row?.total
                        ? 'green.dark'
                        : null,
                  }}
                >
                  <TableCell
                    align="center"
                    sx={{
                      whiteSpace: 'nowrap',
                      fontStyle: row?.isChild ? 'italic' : null,
                      backgroundColor: row?.isChild ? 'white' : null,
                      color: row?.isChild ? 'grey.light' : 'white',
                    }}
                  >
                    {row?.label ? t(row.label) : null}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      whiteSpace: 'nowrap',
                      fontStyle: row?.isChild ? 'italic' : null,
                      backgroundColor: row?.isChild ? 'white' : null,
                      color: row?.isChild ? 'grey.main' : 'white',
                    }}
                  >
                    {row.manual}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      whiteSpace: 'nowrap',
                      fontStyle: row?.isChild ? 'italic' : null,
                      backgroundColor: row?.isChild ? 'white' : null,
                      color: row?.isChild ? 'grey.main' : 'white',
                    }}
                  >
                    {row.semiAutomated}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      whiteSpace: 'nowrap',
                      fontStyle: row?.isChild ? 'italic' : null,
                      backgroundColor: row?.isChild ? 'white' : null,
                      color: row?.isChild ? 'grey.main' : 'white',
                    }}
                  >
                    {row.automated}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export { RevenueModel };
