import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useMotionValueEvent,
} from 'framer-motion';
import { RiveAnimationComponent } from '../animations/RiveAnimationComponent';
import { VideoPlayer } from '../../plugins/VideoPlayer.js';
import useIntuTheme from '../../hooks/useIntuTheme.js';

const SingleZoomInOutComponent = ({
  src,
  type,
  stateMachines,
  autoplay = true,
  title = '',
  description = '',
  footerContent = null,
  stickyval = 0.5,
  unstickyval = 1,
  scaleThreshold = 0.85,
  progress = [0.5, 0.8, 1],
  zoom = [0, 0.1, 1],
  titleMT = '2rem',
  titleMB = '2rem',
  height,
  width,
  customLabels,
  ...props
}) => {
  const headerRef = useRef(null);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  });
  const footerScrollProgress = useTransform(scrollYProgress, progress, zoom);

  const theme = useTheme();
  const { mobile } = useIntuTheme();

  // Smooth transitions for opacity and position based on scroll
  const opacity = useSpring(useTransform(scrollYProgress, [0.3, 0.7], [0, 1]), {
    stiffness: 100,
    damping: 20,
  });

  // Updated translateY to smoothly scroll the title out
  const translateY = useSpring(
    useTransform(scrollYProgress, [stickyval, unstickyval], ['0%', '-100%']),
    { stiffness: 100, damping: 20 },
  );

  const isSticky = useTransform(
    scrollYProgress,
    [scaleThreshold, 1],
    [true, false],
  );
  const [isStickyState, setIsStickyState] = useState(true);

  useMotionValueEvent(scrollYProgress, 'change', () => {});

  useEffect(() => {
    return isSticky.onChange((value) => setIsStickyState(value));
  }, [isSticky]);

  return (
    <Grid
      container
      sx={{
        padding: { xs: '0 1rem', sm: '0 2rem', md: '0 3rem' },
        maxWidth: '100%', // Ensure it never exceeds the viewport
        margin: '0 auto',
        overflowX: 'hidden', // Prevent horizontal scroll
        boxSizing: 'border-box', // Include padding in width calculation
      }}
      {...props}
    >
      {/* Title */}
      <Grid
        item
        textAlign="center"
        sx={{
          position: isStickyState ? 'sticky' : 'relative',
          top: titleMT,
          marginTop: titleMT,
          marginBottom: titleMB,
          zIndex: 2,
          width: '100%',
          padding: { xs: '0 1rem' }, // Add padding for small screens
          boxSizing: 'border-box',
        }}
      >
        <motion.div
          ref={headerRef}
          style={{
            opacity,
            y: translateY,
            transition: 'opacity 0.1s ease-in-out',
            marginTop: '10vh',
            width: '100%',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              whiteSpace: 'normal',
              padding: 0,
              margin: 0,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body"
            sx={{
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              whiteSpace: 'normal',
              padding: 0,
              margin: 0,
            }}
          >
            {description}
          </Typography>
        </motion.div>
      </Grid>
      {/* Content */}
      <Grid
        item
        sx={{
          width: '100%',
          padding: 0,
          overflowX: 'hidden', // Explicitly prevent horizontal overflow
          overflowY: 'hidden', // Ensure clean vertical rendering
          boxSizing: 'border-box', // Include padding in container's width
        }}
      >
        <motion.div ref={ref} style={{ scale: scrollYProgress, opacity }}>
          {type === 'animation' ? (
            <RiveAnimationComponent
              key={src}
              src={src}
              stateMachines={stateMachines}
              autoPlay={autoplay}
              width={mobile ? '90vw' : width}
              height={mobile ? '90vw' : height}
              customLabels={customLabels}
            />
          ) : type === 'image' ? (
            <Box
              component="img"
              sx={{
                width: '100%',
                height: 'auto',
                display: 'block', // Prevent image from causing horizontal scroll
              }}
              alt={title}
              src={src}
            />
          ) : (
            <VideoPlayer videoSource={src} controls={true} />
          )}
        </motion.div>
      </Grid>

      {/* Footer */}
      {footerContent && (
        <Grid
          item
          xs={12}
          sx={{
            padding: { xs: '0 1rem' },
            overflowX: 'hidden', // Prevent horizontal overflow
            boxSizing: 'border-box',
          }}
        >
          <motion.div
            style={{
              opacity: footerScrollProgress,
              marginTop: '3rem',
              width: '100%',
            }}
          >
            <Typography
              variant="body"
              sx={{
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                margin: '0 auto',
              }}
            >
              {footerContent}
            </Typography>
          </motion.div>
        </Grid>
      )}
    </Grid>
  );
};

export const SimpleZoomInOutcomponent = ({ srcArray, ...props }) => {
  return srcArray.map((element, index) => (
    <SingleZoomInOutComponent key={index} {...element} {...props} />
  ));
};
