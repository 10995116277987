import { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Chip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../hooks/axios/useAxiosPrivate';
import { convertFileToBase64 } from '../../helpers/convertFileToBase64';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IntuIconButton from '../buttons/IntuIconButton';
import NewAttributeDialog from './NewAttributeDialog';

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  minHeight: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const formatKey = (key) => {
  return key
    .split('_')
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');
};

const formatDataLabel = ({ attribute, value }) => {
  return `${formatKey(attribute)} : ${value}`;
};

const ExtractDataDialog = ({
  isOpen,
  handleConfirm,
  handleClose,
  productId,
  productAttributeOptions,
}) => {
  const { t: transDialog } = useTranslation('dialogs');
  const { t: transButtons } = useTranslation('buttons');
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();

  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [isExtractionLoading, setIsExtractionLoading] = useState(false);
  const [previewChips, setPreviewChips] = useState([]);
  const [extractedData, setExtractedData] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [selectedExtractedData, setSelectedExtractedData] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const handleUpload = async (e) => {
    if (file) {
      console.log('Uploading file:', file);

      if (file.type === 'application/pdf') {
        let fileUrl = '';
        try {
          setPreviewURL(URL.createObjectURL(file));
          setFile(file);

          const base64File = await convertFileToBase64(file);

          const requestData = {
            file: base64File,
            file_type: 'pdf',
            file_name: file.name,
            folder: `products/${productId}/documents`,
          };

          const { data, status } = await axiosData.post(
            '/api/aws/content/add',
            JSON.stringify(requestData),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );

          if (status === 200) {
            setFileUrl(data.data);
            fileUrl = data.data;
          }
        } catch (error) {
          console.error('error', error);
        } finally {
          e.target.value = null;
        }

        setIsExtractionLoading(true);
        try {
          const payload = {
            url: fileUrl,
            fileName: file.name,
            productId,
          };

          const response = await axios.post(
            '/api/chatgpt/get-data-extraction',
            payload,
            {
              responseType: 'stream',
              adapter: 'fetch',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );

          if (response.status === 200) {
            const reader = response.data.getReader();
            const decoder = new TextDecoder();

            let result = '';
            let previewChip = '';
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              const chunk = decoder.decode(value, { stream: true });

              result += chunk;
              previewChip += chunk;

              if (chunk.includes('}')) {
                setPreviewChips((prevState) => {
                  let cleaned = previewChip.replace(
                    /[\[\]\s]|,(?=[^,]*$)/g,
                    '',
                  );
                  cleaned = cleaned.trim();
                  try {
                    const newChip = JSON.parse(cleaned);
                    previewChip = '';
                    return [...prevState, newChip];
                  } catch (error) {
                    console.warn('unable to json parse', error);
                    previewChip = '';
                    return [...prevState];
                  }
                });
              }
            }
            setExtractedData(JSON.parse(result));
          }
        } catch (error) {
          console.error('error', error);
        } finally {
          setIsExtractionLoading(false);
        }
      }
    }
  };

  const handleItemEdit = (key, value) => {
    setEditingItem({
      attributeKey: key,
      attributeValue: value,
    });
  };

  const handleItemAdd = (key, value) => {
    const { [key]: itemToRemove, ...data } = extractedData;
    setExtractedData({ ...data });
    setSelectedExtractedData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleItemRemove = (key, value) => {
    const { [key]: itemToRemove, ...data } = selectedExtractedData;
    setSelectedExtractedData({ ...data });
    setExtractedData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleEditConfirm = (newAttributeType) => {
    setSelectedExtractedData({
      ...selectedExtractedData,
      [editingItem.attributeKey]: newAttributeType,
    });
    setEditingItem(null);
  };

  const onConfirm = async () => {
    setFile(null);
    setPreviewURL(null);
    setExtractedData(null);
    setSelectedExtractedData(null);

    if (selectedExtractedData) {
      const formattedExtractedData = Object.keys(selectedExtractedData).map(
        (key) => selectedExtractedData[key],
      );
      handleConfirm(formattedExtractedData);
    } else {
      handleConfirm(null);
    }

    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    try {
      const payload = {
        fileName,
      };
      // Remove from collection
      const { data, status } = await axios.put(
        `/api/products/update/${productId}/delete-file`,
        payload,
      );

      if (status === 200) {
        console.log('deleted', data, status);
      }

      const bucketPayload = {
        folder: `products/${productId}/documents/${fileName}`,
      };

      // Remove from DO bucket
      await axios.post('/api/aws/content/delete', bucketPayload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('back finally');
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Extract Data</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        {!previewURL ? (
          <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the file here...</Typography>
            ) : (
              <Typography>
                Drag and drop a file here, or click to select a file to extract
                attributes from
              </Typography>
            )}
          </div>
        ) : null}
        {file && (
          <Typography variant="body2" mt={2}>
            Selected file: {file.name}
          </Typography>
        )}

        <Box display="flex" gap={2} mt={4}>
          {previewURL ? (
            <Box minWidth={400}>
              <embed
                src={`${previewURL}#page=1`}
                type="application/pdf"
                width="100%"
                height="500px"
              />
            </Box>
          ) : null}
          <Box
            width="50%"
            maxHeight={500}
            display="flex"
            flexDirection="column"
          >
            <Box height={40}>
              {isExtractionLoading && (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography mr={2}>
                    {transDialog('UploadDocumentDialog.loading')}
                  </Typography>
                  <CircularProgress />
                </Box>
              )}
            </Box>
            {isExtractionLoading && previewChips.length ? (
              <Box mt={2}>
                {previewChips.map((pc) => {
                  return (
                    <Chip
                      key={pc.value + Math.random()}
                      label={`${pc.attribute} ${pc.value} ${pc.attribute_type}`}
                      variant="outlined"
                      sx={{
                        mb: 1,
                        background: (theme) => theme.palette.secondary.main,
                      }}
                      onDelete={() => {}}
                      deleteIcon={
                        <Box>
                          <IconButton size="small" color="warning" disabled>
                            <EditIcon
                              sx={{
                                color: 'warning.main',
                              }}
                            />
                          </IconButton>
                          <IconButton size="small" disabled>
                            <AddIcon
                              sx={{
                                color: 'primary.main',
                              }}
                            />
                          </IconButton>
                        </Box>
                      }
                    />
                  );
                })}
              </Box>
            ) : null}
            {extractedData && (
              <>
                <Typography variant="p">
                  {transDialog('UploadDocumentDialog.extracted')}
                </Typography>
                <Box mt={2}>
                  {Object.keys(extractedData).length &&
                    Object.keys(extractedData).map((dataKey) => {
                      return (
                        <Chip
                          key={dataKey}
                          label={formatDataLabel(extractedData[dataKey])}
                          variant="outlined"
                          sx={{
                            mb: 1,
                            background: (theme) => theme.palette.secondary.main,
                          }}
                          onDelete={() => {}}
                          deleteIcon={
                            <Box>
                              <IconButton
                                size="small"
                                color="warning"
                                onClick={() =>
                                  handleItemEdit(
                                    dataKey,
                                    extractedData[dataKey],
                                  )
                                }
                              >
                                <EditIcon
                                  sx={{
                                    color: 'warning.main',
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleItemAdd(dataKey, extractedData[dataKey])
                                }
                              >
                                <AddIcon
                                  sx={{
                                    color: 'primary.main',
                                  }}
                                />
                              </IconButton>
                            </Box>
                          }
                        />
                      );
                    })}
                </Box>
              </>
            )}
            {selectedExtractedData && (
              <>
                <Typography variant="p" mt={2}>
                  {transDialog('UploadDocumentDialog.chose')}
                </Typography>
                <Box mt={2}>
                  {Object.keys(selectedExtractedData).length ? (
                    <>
                      {Object.keys(selectedExtractedData).map((dataKey) => {
                        return (
                          <Chip
                            key={dataKey}
                            label={formatDataLabel(
                              selectedExtractedData[dataKey],
                            )}
                            variant="outlined"
                            sx={{
                              mb: 1,
                              '& .MuiChip-deleteIcon': {
                                color: 'error.light',
                                '&:hover': {
                                  color: 'error.dark',
                                },
                              },
                            }}
                            onDelete={() =>
                              handleItemRemove(
                                dataKey,
                                selectedExtractedData[dataKey],
                              )
                            }
                          />
                        );
                      })}
                    </>
                  ) : null}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-between',
          paddingBottom: '16px',
          paddingRight: '16px',
          paddingLeft: '16px',
        }}
      >
        <IntuIconButton
          size="small"
          type="cancel"
          variant="outlined"
          tooltipTitle={transButtons('close')}
          onClick={handleClose}
        />
        <Box>
          <IntuIconButton
            size="small"
            type="extract"
            variant="outlined"
            tooltipTitle={transButtons('extract')}
            onClick={handleUpload}
            disabled={!file || isExtractionLoading}
          />
          <IntuIconButton
            size="small"
            type="submit"
            variant="outlined"
            tooltipTitle={transButtons('confirm')}
            onClick={onConfirm}
            disabled={!file || isExtractionLoading || !extractedData}
          />
        </Box>
      </DialogActions>
      {!!editingItem ? (
        <NewAttributeDialog
          isOpen={!!editingItem}
          handleConfirm={handleEditConfirm}
          handleClose={() => setEditingItem(null)}
          selectedAttribute={editingItem}
          productAttributeOptions={productAttributeOptions}
        />
      ) : null}
    </Dialog>
  );
};

export default ExtractDataDialog;
