import { useContext, useMemo, useState } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import IntuIconButton from '../buttons/IntuIconButton';
import LanguagesData from '../../data/LanguagesData.json';
import { axiosPrivate } from '../../hooks/axios/axios';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TranslateIcon from '@mui/icons-material/Translate';
import { IntuRichTextEditor } from '../IntuRichTextEditor/IntuRichtTextEditor';
import { useTranslation } from 'react-i18next';
import ResponseContext from '../../context/providers/ResponseProvider';

const languageOptions = LanguagesData.filter((language) => language.supported);

export const getRawContentState = (text) => {
  const contentState = ContentState.createFromText(text);
  const editorState = EditorState.createWithContent(contentState);
  return convertToRaw(editorState.getCurrentContent());
};

const ProductDescription = ({ descriptions, setProduct, setIsSaving }) => {
  const { t: transFields } = useTranslation('fields');
  const { setStatusMsg } = useContext(ResponseContext);

  const [selectedLanguage, setSelectedLanguage] = useState({
    name: 'English',
    code: 'en',
  });
  const [isTweakLoading, setIsTweakLoading] = useState(false);
  const [isTranslateLoading, setIsTranslateLoading] = useState(false);

  const selectedDescription = useMemo(() => {
    return (
      descriptions.find((d) => d.code === selectedLanguage.code) || {
        name: selectedLanguage.name,
        code: selectedLanguage.code,
        description: getRawContentState(''),
      }
    );
  }, [selectedLanguage, descriptions]);

  const [localDescription, setLocalDescription] = useState(null);
  const handleRteDescriptionChange = (event) => {
    const rteContent = convertToRaw(event.getCurrentContent());
    setLocalDescription(rteContent);
  };

  const handleRteDescriptionBlur = () => {
    if (!localDescription) return;

    setProduct((prevState) => {
      // Add new language description if it doesnt exist
      const found = prevState.descriptions.find(
        (d) => d.code === selectedLanguage.code,
      );

      if (!found) {
        const newDescription = {
          name: selectedLanguage.name,
          code: selectedLanguage.code,
          description: localDescription,
        };

        return {
          ...prevState,
          descriptions: [...prevState.descriptions, newDescription],
        };
      }

      // Else update existing
      const newDescriptions = prevState.descriptions.map((description) => {
        if (description.code === selectedLanguage.code) {
          return {
            ...description,
            description: localDescription,
          };
        }
        return description;
      });

      return {
        ...prevState,
        descriptions: newDescriptions,
      };
    });
  };

  const handleRteDescriptionSave = (e) => {
    const parsed = JSON.parse(e);
    setProduct((prevState) => {
      // Add new language description if it doesnt exist
      const found = prevState.descriptions.find(
        (d) => d.code === selectedLanguage.code,
      );

      if (!found) {
        const newDescription = {
          name: selectedLanguage.name,
          code: selectedLanguage.code,
          description: parsed,
        };

        return {
          ...prevState,
          descriptions: [...prevState.descriptions, newDescription],
        };
      }

      // Else update existing
      const newDescriptions = prevState.descriptions.map((description) => {
        if (description.code === selectedLanguage.code) {
          return {
            ...description,
            description: parsed,
          };
        }
        return description;
      });

      return {
        ...prevState,
        descriptions: newDescriptions,
      };
    });
  };

  const handleAITweak = async () => {
    if (!selectedDescription.description) return;
    setIsTweakLoading(true);
    setIsSaving(true);

    const description = selectedDescription.description.blocks[0].text;
    const payload = {
      messages: [
        {
          role: 'assistant',
          content: `Tweak the following product description to make it more compelling in this language: ${selectedLanguage.name} ${description}`,
        },
      ],
      temperature: 0.1,
    };

    try {
      const { data, status } = await axiosPrivate.post(
        '/api/chatgpt/get-sentence',
        payload,
      );
      if (status === 200) {
        const [tweaked] = data.data;
        const rawContentState = getRawContentState(tweaked.message.content);

        setProduct((prevState) => {
          const newDescription = {
            language: selectedLanguage.name,
            code: selectedLanguage.code,
            description: rawContentState,
          };

          const newDescriptions = prevState.descriptions.map((description) => {
            if (selectedLanguage.code === description.code)
              return newDescription;
            return description;
          });
          return {
            ...prevState,
            descriptions: newDescriptions,
          };
        });
        setStatusMsg({
          open: true,
          status: data.status,
          message: `Successfully tweaked product description`,
        });
      }
    } catch (error) {
      console.error('error', error);
      setStatusMsg({
        open: true,
        status: 'error',
        message: `Error: ${error.message}`,
      });
    } finally {
      setIsTweakLoading(false);
      setIsSaving(false);
    }
  };

  const handleTranslate = async () => {
    if (!languageOptions.length || !selectedDescription.description) return;

    setIsTranslateLoading(true);
    setIsSaving(true);

    // Create a list removing currently selected description language option
    const languages = languageOptions
      .filter((option) => option.name !== selectedDescription.name)
      .map((option) => option.name)
      .join(' ');

    const description = selectedDescription.description.blocks[0].text;
    const payload = {
      messages: [
        {
          role: 'assistant',
          content: `Translate the following product description to the following languages ${languages}. The result should be an array of objects that look like { name: translatedLanguageName, description: translatedDescription }. It should be a flat object that I can successfully JSON.parse(). If I cannot JSON.parse() the array of objects please try again. No fluff or chatter please!`,
        },
        { role: 'user', content: `product description: ${description}.` },
      ],
      temperature: 0.1,
    };

    try {
      const { data, status } = await axiosPrivate.post(
        '/api/chatgpt/get-sentence',
        payload,
      );
      if (status === 200) {
        const [translated] = data.data;
        const translatedArray = JSON.parse(translated.message.content);
        if (!Array.isArray(translatedArray)) return;

        setProduct((prevState) => {
          const newDescriptions = languageOptions.map((option) => {
            if (option.name === selectedDescription.name) {
              return {
                name: option.name,
                code: option.code,
                description: getRawContentState(description),
              };
            }

            const foundTranslation = translatedArray.find(
              ({ name }) => name === option.name,
            );

            const rawContentState = getRawContentState(
              foundTranslation.description,
            );
            return {
              name: option.name,
              code: option.code,
              description: rawContentState,
            };
          });

          return {
            ...prevState,
            descriptions: newDescriptions,
          };
        });
        setStatusMsg({
          open: true,
          status: data.status,
          message: `Successfully translated to: ${languages}`,
        });
      }
    } catch (error) {
      console.error('error', error);
      setStatusMsg({
        open: true,
        status: 'error',
        message: `Error: ${error.message}`,
      });
    } finally {
      setIsTranslateLoading(false);
      setIsSaving(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6">
        {transFields('products.product_desc')}
      </Typography>
      <FormControl sx={{ width: '200px' }}>
        <InputLabel id="language-select">{transFields('language')}</InputLabel>
        <Select
          label="Language"
          labelId="language-select"
          value={selectedLanguage.code}
          name="language"
          defaultValue="lb"
          sx={{ mb: 2 }}
        >
          {languageOptions.map((language) => {
            return (
              <MenuItem
                key={language.code}
                value={language.code}
                onClick={() => setSelectedLanguage(language)}
              >
                {language.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <IntuRichTextEditor
        id="product_description"
        name="product_description"
        required={true}
        disabled={false}
        controls={[
          'title',
          'bold',
          'italic',
          'underline',
          'fontColor',
          'highlight',
          'undo',
          'redo',
          'numberList',
          'bulletList',
          'quote',
          'clear',
          'save',
        ]}
        className="form-select-field"
        aria-describedby="uidnote"
        variant="standard"
        label={'Enter your product description here'}
        placeholder={'Enter your product description here'}
        defaultValue={
          selectedDescription.description
            ? JSON.stringify(selectedDescription.description)
            : null
        }
        onChange={handleRteDescriptionChange}
        onBlur={handleRteDescriptionBlur}
        onSave={handleRteDescriptionSave}
        inlineToolbar={true}
        // error={errors?.intro && touched?.intro ? true : false}
        // helperText={errors?.intro && touched?.intro ? errors?.intro : null}
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <IntuIconButton
          type="connect"
          tooltipTitle="Tweak with AI"
          variant="outlined"
          color="primary"
          onClick={handleAITweak}
          isLoading={isTweakLoading}
          disabled={!selectedDescription.description || isTranslateLoading}
          IconComponent={AutoFixHighIcon}
        />
        <IntuIconButton
          type="info"
          tooltipTitle="Translate"
          variant="outlined"
          onClick={handleTranslate}
          isLoading={isTranslateLoading}
          disabled={!selectedDescription.description || isTweakLoading}
          IconComponent={TranslateIcon}
        />
      </Box>
    </Box>
  );
};

export default ProductDescription;
