import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import CampaignIcon from '@mui/icons-material/Campaign';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import FeedIcon from '@mui/icons-material/Feed';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useTheme } from '@mui/styles';
import { NavBarDrawerContext } from '../../../context/providers/NavBarDrawerContextProvider';
import useAuth from '../../../hooks/useAuth';
import { NavList, NavItem } from '../../../framer-motion/animations';

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

const UserNavItems = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { auth } = useAuth();
  const { handleDrawerClose } = useContext(NavBarDrawerContext);

  const { t } = useTranslation('buttons', {
    keyPrefix: 'navigation',
  });

  const roleNavItems = [
    {
      index: 10,
      key: 'SalesTeam',
      label: t('sales_team'),
      color: theme.palette.text.primary,
      icon: (
        <PeopleIcon sx={{ color: theme.palette.secondary.backgroundColor }} />
      ),
      target: () => navigate('/app/company/salesteam'),
      visible: true,
    },
    {
      index: 20,
      key: 'Campaigns',
      label: t('campaigns'),
      color: theme.palette.text.primary,
      icon: (
        <CampaignIcon sx={{ color: theme.palette.secondary.backgroundColor }} />
      ),
      target: () => navigate('/app/company/campaigns'),
      visible: true,
    },
    {
      index: 30,
      key: 'Leads',
      label: t('leads'),
      color: theme.palette.text.primary,
      icon: (
        <LocalMallIcon
          sx={{ color: theme.palette.secondary.backgroundColor }}
        />
      ),
      target: () => navigate('/app/company/leads'),
      visible: true,
    },
    {
      index: 40,
      key: 'Posts',
      label: t('posts'),
      color: theme.palette.text.primary,
      icon: (
        <FeedIcon sx={{ color: theme.palette.secondary.backgroundColor }} />
      ),
      target: () => navigate('/app/user/posts'),
      event: 'Posts',
      visible: 'drawer',
    },
    {
      index: 50,
      key: 'Products',
      label: t('products'),
      color: theme.palette.text.primary,
      icon: (
        <PrecisionManufacturingIcon
          sx={{ color: theme.palette.secondary.backgroundColor }}
        />
      ),
      target: () => navigate('/app/manage-products/list'),
      visible: !IS_PRODUCTION,
    },
    {
      index: 60,
      key: 'Brands',
      label: 'Find Brands',
      icon: <TravelExploreIcon />,
      target: () => navigate('/app/find/brands'),
      visible:
        !IS_PRODUCTION &&
        auth?.user_info?.account_type === 'salesrep' &&
        auth?.user_info?.account_complete,
    },
    {
      index: 70,
      key: 'Sales Reps',
      label: 'Find Sales Engineers',
      icon: <TravelExploreIcon />,
      target: () => navigate('/app/find/salesreps'),
      visible:
        !IS_PRODUCTION &&
        auth?.user_info?.account_type === 'manufacturer' &&
        auth?.user_info?.account_complete,
    },
  ].sort((a, b) => {
    if (a.index < b.index) return -1;
    if (a.index > b.index) return 1;
    return 0;
  });

  return (
    auth?.user_info?.account_complete &&
    roleNavItems.map(
      (menuItem) =>
        menuItem.visible && (
          <motion.div
            variants={NavList}
            initial="hidden"
            animate="visible"
            key={menuItem.key}
          >
            <ListItem key={menuItem.key} sx={{ display: 'block' }}>
              <motion.div variants={NavItem} whileHover={{ scale: 1.1 }}>
                <ListItemButton
                  sx={{
                    height: '2rem',
                    justifyContent: 'initial',
                    px: 2.5,
                    fontSize: '14px',
                  }}
                  onClick={() => {
                    menuItem.target();
                    handleDrawerClose();
                  }}
                >
                  <ListItemIcon
                  // sx={{
                  //   color:
                  //     menuItem?.color || theme.palette.primary.contrastText,
                  // }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    // sx={{ color: theme.palette.primary.contrastText }}
                    primary={menuItem.label}
                    disableTypography
                  />
                </ListItemButton>
              </motion.div>
            </ListItem>
          </motion.div>
        ),
    )
  );
};

export default UserNavItems;
