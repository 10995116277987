import React, { useEffect, useRef, useState } from 'react';
import ReactMapboxGl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './maps.css';

ReactMapboxGl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

function IntutecLocationsMap() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-103.4617);
  const [lat, setLat] = useState(44.58207);
  const [zoom, setZoom] = useState(3);

  const { t: transCommon } = useTranslation('common');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new ReactMapboxGl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/intutecio/clmbwv14801ay01pf80c7hkm6',
      projection: 'globe',
      center: [lng, lat],
      zoom: zoom,
      attributionControl: false,
      // dragPan: false,
      // dragRotate: false,
      // scrollZoom: false,
      // touchZoom: false,
      // touchRotate: false,
      // keyboard: false,
      // doubleClickZoom: false
    });

    const locations = [
      {
        name: 'Palo Alto',
        address: '3101 Park Blvd.<br/>Palo Alto, CA 94306',
        type: 'Headquarters',
        phone: '+1 888 471 3736',
        color: 'green',
        coordinates: [-122.13635, 37.42533],
      },
      {
        name: 'Memphhis',
        address: '1609 Bartlett Road<br/>Memphis, TN 38134',
        type: 'Logistic Center',
        phone: '+1 888 471 3736',
        color: 'grey',
        coordinates: [-89.88139, 35.170138],
      },
      {
        name: 'Brooklyn',
        address: '1 Dock 72 Wy<br/>Brooklyn, NY 11205',
        phone: '+1 888 471 3736',
        type: 'Office',
        coordinates: [-73.972239, 40.700441],
      },
    ];

    // Create default markers
    locations.map((location, index) => {
      const popup = new ReactMapboxGl.Popup({ offset: 25 }) // create popup
        .setHTML(
          `<h3>${location.type}</h3><p>${location.address}</p><p>${location.phone}</p>`,
        );

      const marker = new ReactMapboxGl.Marker({
        color: location.color === 'green' ? '#A8C957' : '#3E3E3E',
        width: '5rem',
        height: '5rem',
        borderRadius: '50%',
        cursor: 'pointer',
      })
        .setLngLat(location.coordinates)
        .setPopup(popup) // add the popup to the marker
        .addTo(map.current);

      // // Open the popup for the first location
      // if (index === 0) {
      //   console.log('Add first ');
      //   popup.addTo(map.current);
      // }
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <Grid item xs={12}>
      <div className="sidebar">
        {/* Longitude: {lng} | Latitude: {lat} */}
        {transCommon('clik_on_location')}
      </div>
      <div ref={mapContainer} className="map-container" />
    </Grid>
  );
}
export default IntutecLocationsMap;
