// Stripe Context, everything in regards to stripe

import { useEffect, useState, createContext, useContext } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import AuthContext from './AuthProvider';
import { UserProfileContext } from './UserProfileProvider';
import { CompanyProfileContext } from './CompanyProfileProvider';

export const StripeContext = createContext({});

export const StripeProvider = ({ children }) => {
  const { auth, isAdmin } = useContext(AuthContext);
  const { userProfile } = useContext(UserProfileContext);
  const { companyProfile } = useContext(CompanyProfileContext);

  const axios = useAxiosPrivate();
  const [stripeUserData, setStripeUserData] = useState({
    account_id: '',
    currently_due: '',
    past_due: '',
    account_Link: '',
    account_balance: 0,
    account_complete: false,
    payment_links: {},
  });
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState('');

  const getStripeAccountDetails = async () => {
    if (!auth.auth_info || auth?.user_info?.account_type === 'intutec') {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsStripeLoading(true);

      const payload = {
        host_url: window.location.host,
      };
      const getStripeAccountDetails = await axios.post(
        '/api/stripe/connect/account/retrieve',
        JSON.stringify(payload),
        {
          signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      const { statusCode, data, metadata } = getStripeAccountDetails.data;
      if (statusCode === 200) {
        let accountLink;

        if (data.currently_due.length || data.past_due.length) {
          // const payload = {
          //   host_url: window.location.host,
          // };
          const getAccountCompleteLink = await axios.post(
            '/api/stripe/connect/account/create-account-link',
            JSON.stringify(payload),
            {
              signal,
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            },
          );
          const { statusCode, data } = getAccountCompleteLink.data;

          if (statusCode === 200) {
            accountLink = data.url;
          }
        }

        setStripeUserData({
          account_id: data.id,
          currently_due: data.currently_due,
          past_due: data.past_due,
          account_link: accountLink,
          payment_links: {
            ...metadata.paymentLinks,
          },
        });
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      controller.abort(signal);
      setIsStripeLoading(false);
    }
  };

  const createSubscription = async (tier) => {
    if (!auth.auth_info || auth?.user_info?.account_type !== 'manufacturer') {
      return;
    }

    const payload = {
      tier,
    };
    try {
      const response = await axios.post(
        '/api/stripe/connect/subscription/create',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      console.log('response', response);
    } catch (error) {
      console.error('error', error);
      return error;
    }
  };

  useEffect(() => {
    getStripeAccountDetails();
  }, [auth?.auth_info, userProfile._id, companyProfile._id, isAdmin]);

  return (
    <StripeContext.Provider
      value={{
        stripeUserData,
        setStripeUserData,
        isStripeLoading,
        stripeErrorMessage,
        createSubscription,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};
