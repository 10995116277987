import { Box } from '@mui/material';
const CartPage = () => {
  return (
    <Box mt={4} px={2} minHeight="80dvh">
      Cart
    </Box>
  );
};

export default CartPage;
