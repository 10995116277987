import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler';
import { useTranslation } from 'react-i18next';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';

const CompanyPaymentMethodFieldContent = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'payment_methods', // Define the Fields Name and ID
  autocompleteID = 'payment_methods', // Define the Fields Name and ID
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'PaymentMethodsField', // KeyPrefix which holds label and placeholder
  transition = true, // Transition true = fade in, false = fade out
  disabled = false, // Disable the Field
  required = false, // Field Required
  variant = 'outlined', // Set the Variant type standard or outlined
}) => {
  const { values, handleBlur, setFieldValue, touched, errors } =
    useFormikContext();

  const { isLoading } = useProcessingHandler();

  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const paymentOptions = ['cc', 'ach', 'bank', 'check'];

  const handlePaymentMethodChange = (e, options, reason, element) => {
    setFieldValue('payment_methods', options);
  };

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <Autocomplete
        multiple
        value={getNestedValue(values, autocompleteID)}
        required={required}
        disabled={disabled || isLoading?.status ? true : false}
        id={fieldID}
        name={fieldID}
        options={paymentOptions}
        onBlur={handleBlur}
        sx={{ paddingBottom: '50px' }}
        className="form-select-field"
        getOptionLabel={(option) => t(`${option}`)}
        onChange={handlePaymentMethodChange}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            variant={variant}
            label={!disabled ? t('label') : null}
            placeholder={!disabled ? t('placeholder') : null}
            error={errors[fieldID] && touched[fieldID] ? true : false}
            helperText={
              errors[fieldID] && touched[fieldID] ? errors[fieldID] : null
            }
          />
        )}
      />
    </FormikFieldWrapper>
  );
};

const CompanyPaymentMethodField = (props) => {
  return <CompanyPaymentMethodFieldContent {...props} />;
};

export default CompanyPaymentMethodField;
