import { useEffect, useMemo, useState } from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import axios from '../../hooks/axios/axios';
import { formatAttribute } from './AttributeBox';
import { formatAttributeValue } from './AttributeValueBox';
import IntuIconButton from '../buttons/IntuIconButton';
import AddAttributeDialog from './AddAttributeDialog';

const AttributeContainer = ({
  product,
  setProduct,
  productAttributeOptions,
  setProductAttributeOptions,
  handleExtractData,
}) => {
  const { t: transButtons } = useTranslation('buttons');
  const [selectedAttributeType, setSelectedAttributeType] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState(
    new Set(product.product_attributes),
  );

  const [editingAttribute, setEditingAttribute] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  useEffect(() => {
    setSelectedAttributes(new Set(product.product_attributes));
  }, [product.product_attributes]);

  useEffect(() => {
    getAttributesOptions();
  }, []);

  const getAttributesOptions = async () => {
    try {
      const { data, status } = await axios.get('/api/product-attributes');
      if (status === 200) {
        setProductAttributeOptions(data.data);
      }
    } catch (error) {
      console.error(error, 'error');
    }
  };

  const handleAttributeDelete = (attribute) => {
    setSelectedAttributes((prevState) => {
      const newAttributes = new Set(prevState);
      newAttributes.delete(attribute);
      return newAttributes;
    });

    setProduct((prevState) => {
      return {
        ...prevState,
        product_attributes: prevState.product_attributes.filter((a) => {
          return attribute.attribute_type !== a.attribute_type;
        }),
      };
    });
  };

  const handleChipSelect = (attribute) => {
    setEditingAttribute(attribute);
    setSelectedAttributeType(attribute.attribute_type);
    setSelectedAttribute(attribute.attribute);
    setSelectedAttributeValue(attribute.value);
  };

  const handleAddAttribute = () => {
    setIsAddDialogOpen(true);
  };

  const selectedTypeSet = useMemo(() => {
    const set = new Set();
    selectedAttributes.forEach((a) => {
      set.add(a.attribute_type);
    });
    return set;
  }, [selectedAttributes]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" width="25%">
        <Typography variant="h6" textTransform="none" fontSize="1.2rem" mr={2}>
          Attributes
        </Typography>
        <IntuIconButton
          size="small"
          type="connect"
          variant="outlined"
          tooltipTitle={transButtons('add')}
          IconComponent={AddIcon}
          onClick={handleAddAttribute}
        />
        <IntuIconButton
          size="small"
          type="extract"
          variant="outlined"
          tooltipTitle={transButtons('extract')}
          onClick={handleExtractData}
        />
      </Box>
      <Box mb={2}>
        {selectedTypeSet.size
          ? selectedTypeSet.map((selectedType) => {
              return (
                <Box>
                  <Typography textTransform="capitalize" my={1}>
                    {selectedType}:
                  </Typography>
                  {selectedAttributes
                    .filter((a) => a.attribute_type === selectedType)
                    .map((selectedAttribute) => {
                      return (
                        <Chip
                          key={
                            selectedAttribute._id ||
                            `${selectedAttribute.attribute}-${selectedAttribute.attribute_type}`
                          }
                          label={`${formatAttribute(selectedAttribute.attribute)} - ${formatAttributeValue(selectedAttribute.value)}`}
                          variant="outlined"
                          clickable
                          onClick={() => handleChipSelect(selectedAttribute)}
                          onDelete={() =>
                            handleAttributeDelete(selectedAttribute)
                          }
                          sx={{ ml: 2 }}
                        />
                      );
                    })}
                </Box>
              );
            })
          : null}
      </Box>
      <AddAttributeDialog
        product={product}
        setProduct={setProduct}
        productAttributeOptions={productAttributeOptions}
        setProductAttributeOptions={setProductAttributeOptions}
        selectedAttributeType={selectedAttributeType}
        setSelectedAttributeType={setSelectedAttributeType}
        selectedAttribute={selectedAttribute}
        setSelectedAttribute={setSelectedAttribute}
        selectedAttributeValue={selectedAttributeValue}
        setSelectedAttributeValue={setSelectedAttributeValue}
        editingAttribute={editingAttribute}
        setEditingAttribute={setEditingAttribute}
        isOpen={isAddDialogOpen}
        handleClose={() => setIsAddDialogOpen(false)}
      />
    </Box>
  );
};

export default AttributeContainer;
