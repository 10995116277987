import { useContext, useEffect, useState } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import LocationsMap from '../../components/maps/LocationsMap';
import { MapLocationByAddressContext } from '../../context/providers/maps/MapLocationByAddressProvider';
import { CircularProgress, Grid } from '@mui/material';
import { geoCodeAddress } from '../../routes/mapRoutes';
import './maps.css';

const MapLocationContent = (disableZoom) => {
  // Formik Values
  const { mapAddress } = useContext(MapLocationByAddressContext);

  //SetUp Axios
  const axios = useAxiosPrivate();

  // Handle Map Rendering
  const [mapData, setMapData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Translator
  const { t } = useTranslation();

  let [locations, setLocations] = useState([
    {
      name: 'Location Name',
      content: 'Location Content',
      coordinates: { latitude: '', longitude: '' },
    },
  ]);

  async function mapLocationOnMap(param) {
    // Additional Parameters
    let name = param?.name || '';
    let content = param?.content || '';

    const getLocation = await geoCodeAddress(param);

    if (getLocation.statusCode === 200) {
      // Update Map Locations
      const newLocations = [
        {
          name: name,
          content: content,
          coordinates: {
            longitude: getLocation.location.lng,
            latitude: getLocation.location.lat,
          },
        },
      ];
      setLocations(newLocations);
      return getLocation;
    }
  }

  // Map Existing Locations when entering stepper tab
  useEffect(() => {
    // Guard Clause
    if (
      !mapAddress?.street ||
      !mapAddress?.state ||
      !mapAddress?.postcode ||
      !mapAddress?.country
    ) {
      return;
    }

    async function getMapLocations(location) {
      // Guard Clause
      if (
        !mapAddress?.street ||
        !mapAddress?.state ||
        !mapAddress?.postcode ||
        !mapAddress?.country
      ) {
        return;
      }

      setIsLoading(true);

      try {
        const coordinates = await mapLocationOnMap(location);

        if (coordinates?.location?.lat > 0 && coordinates?.location?.lng) {
          setTimeout(() => {
            setMapData(true);
          }, 600);
        } else {
          setMapData(false);
        }
        return coordinates;
      } catch (error) {
        console.log('Error while trying to map Locations: ', error);
      } finally {
        setIsLoading(false);
      }
    }

    let location = {
      name: mapAddress.name,
      street: mapAddress.street,
      postcode: mapAddress.postcode,
      state: mapAddress.state,
      country: mapAddress.country,
    };

    getMapLocations(location);
  }, [mapAddress]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        {mapData ? (
          <LocationsMap
            initialZoom={10}
            label={true}
            locations={locations}
            disableZoom
          />
        ) : isLoading ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

const MapLocation = ({ address, disableZoom }) => {
  return <MapLocationContent address={address} disableZoom />;
};

export { MapLocation };
