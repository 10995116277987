import React, { useState } from 'react';
import './headers.css';
import investorBanner from '../../../assets/how-it-works/manufacturers.png';
import { Grid, Typography, Container, Button } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { PlayCircleOutlineOutlined } from '@mui/icons-material';
import { VideoPlayer } from '../../../plugins/VideoPlayer';

const InvestorHeaderContent = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const leadInfo = props?.leadInfo;
  const companyName = leadInfo?.company;

  let image;
  if (leadInfo?.company_banner_url) {
    image = leadInfo.company_banner_url;
  } else {
    image = investorBanner;
  }
  function handleVideoOpen() {
    setIsOpen(!isOpen);
  }
  const { t: transButtons } = useTranslation('buttons');

  return (
    <Container maxWidth="auto" disableGutters>
      <Grid className="intu__divider">
        <Grid
          container
          className="article"
          spacing={2}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            minHeight: '100vh',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Grid
            item
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'var(--color-hero-overlay)', // grey with 70% opacity
              zIndex: 1,
            }}
          />

          {/* Intro Text */}
          <Grid
            item
            xs={12}
            sx={{
              position: 'relative',
              padding: '0 1rem 0 1rem',
              zIndex: 2,
            }}
          >
            <Typography variant="h1" className="header" sx={{ color: 'white' }}>
              <Trans
                i18nKey="context.headers.investor.title"
                values={{ companyName: companyName }}
              />
              {leadInfo?.headline_title}
            </Typography>
          </Grid>

          {/* Video */}
          <Grid
            item
            container
            direction="row"
            spacing={{ xs: 2, md: 12 }}
            justifyContent="center"
            sx={{ zIndex: 2 }}
          >
            <Grid item>
              <Button
                variant="contained"
                startIcon={<PlayCircleOutlineOutlined />}
                sx={{ borderRadius: '50px' }}
                onClick={handleVideoOpen}
              >
                {transButtons('view_video')}
              </Button>

              <VideoPlayer
                modal={true}
                open={isOpen}
                playing={true}
                onClose={() => setIsOpen(false)}
                videoSource="https://in2tec.nyc3.cdn.digitaloceanspaces.com/investors/Investor_Application.mp4"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export { InvestorHeaderContent };
