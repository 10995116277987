import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import MessageIcon from '@mui/icons-material/Message';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import useAuth from '../../hooks/useAuth';
import getUserImage from '../../helpers/getUserImage';
import useUser from '../../hooks/useUser';

// Haversine formula to calculate distance between two points
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const toRadians = (degree) => (degree * Math.PI) / 180;
  const R = 3958.8; // Radius of the Earth in miles

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Check for calculation error
  if (isNaN(c)) return null;

  return (R * c).toFixed(0); // Distance in miles
};

const ProductRepresentatives = ({
  productRepresentatives,
  handleMessage,
  chats,
}) => {
  const { auth } = useAuth();
  const { user } = useUser();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography mb={4}>Available Sales Engineers:</Typography>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.default',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '5px',
        }}
      >
        {productRepresentatives?.map((rep) => {
          const found = chats.find((chat) => chat.sender._id === rep._id);
          const isDisabled = rep._id === auth?.user_info?._id || !!found;
          const distanceBetween = calculateDistance(
            Number(user.location.latitude),
            Number(user.location.longitude),
            rep.address.coordinates?.latitude,
            rep.address.coordinates?.longitude,
          );

          return (
            <ListItem key={rep._id}>
              <ListItemAvatar>
                <Avatar src={getUserImage(rep)} alt="profile picture">
                  <FaceIcon sx={{ color: 'primary.dark' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  rep.account_type === 'intutec'
                    ? `${rep.name}`
                    : `${rep.first_name} ${rep.last_name}`
                }
                secondary={
                  rep.address
                    ? `${rep?.address?.city}, ${rep.address?.state.toUpperCase()}`
                    : ''
                }
              />
              {distanceBetween ? (
                <Typography mx={1}>{distanceBetween} miles</Typography>
              ) : null}
              <IntuIconButton
                size="small"
                type="add"
                variant="outlined"
                tooltipTitle={'Message'}
                onClick={() => handleMessage(rep)}
                IconComponent={MessageIcon}
                disabled={isDisabled}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default ProductRepresentatives;
