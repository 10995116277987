import { Container } from '@mui/material';
import CompanyProductList from '../../context/product/CompanyProductList';
import { ManageProductContext } from '../../context/product/ManageProductContext';
import { ProductProvider } from '../../context/providers/ProductContextProvider';
import { useLocation } from 'react-router-dom';
import FormContextProvider from '../../context/providers/FormContextProvider';
import DashboardLayoutComponent from '../../components/layout/DashboardLayout';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import SalesRepProductList from '../../context/product/SalesRepProductList';

function AdminProducts() {
  const location = useLocation();
  const { auth } = useAuth();
  const mode = location?.state?.mode || 'list';
  const { t } = useTranslation();

  return (
    <ProductProvider>
      <FormContextProvider>
        <Container maxWidth="xl" style={{ marginTop: '8rem' }}>
          {mode === 'edit' ? (
            <ManageProductContext />
          ) : auth.user_info.account_type === 'manufacturer' ? (
            <DashboardLayoutComponent
              dashboardTitle={t('pages.CompanyProductsPage.title')}
              dashboardDescription={t('pages.CompanyProductsPage.description')}
            >
              <CompanyProductList />
            </DashboardLayoutComponent>
          ) : auth.user_info.account_type === 'salesrep' ? (
            <DashboardLayoutComponent
              dashboardTitle="Products You Represent"
              dashboardDescription="Share and manage the products you represent"
            >
              <SalesRepProductList />
            </DashboardLayoutComponent>
          ) : null}
        </Container>
      </FormContextProvider>
    </ProductProvider>
  );
}

export default AdminProducts;
