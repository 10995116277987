import React from 'react';
import { useCallback } from 'react';
import { Grid } from '@mui/material';
import {
  intuParticleMobile,
  intuParticleDesktop,
  intuParticleIpad,
} from '../../animations/intuParticles';
import './headers.css';

// Particle Animations
import Particles from 'react-particles';
import { loadFull } from 'tsparticles'; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

import { tsParticles } from 'tsparticles-engine';
import { loadPolygonMaskPlugin } from '@in2tec/tsparticles-mask-plugin';
import useWindowDimensions from '../../hooks/useWindowDimension';

const AboutCompanyHeaderContent = () => {
  const { width } = useWindowDimensions();

  // SetUp Particle Options based on screen size
  let particleConfig;
  if (width > 820) {
    particleConfig = intuParticleDesktop;
  } else if (width > 390) {
    particleConfig = intuParticleIpad;
  } else {
    particleConfig = intuParticleMobile;
  }

  // Particle Animations
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
    await container;
  }, []);

  (async () => {
    await loadPolygonMaskPlugin(tsParticles);
  })();

  return (
    <Grid
      container
      disableGutters
      direction="column"
      textAlign="center"
      sx={{ marginBottom: -0.8 }}
    >
      <Particles
        width="100%"
        height="600px"
        id="tsparticles"
        options={particleConfig}
        init={particlesInit}
        loaded={particlesLoaded}
      />
    </Grid>
  );
};

export { AboutCompanyHeaderContent };
